<mat-card appearance="outlined" class="post-card mat-elevation-z0">
  <div class="post-container">

      <div class="post-top">
        <div class="comment-info">

          <div class="image-container">
            <img class="profile-image" [src]="post.postedBy.avatar" alt="" />
          </div>
          <div class="info-container">
            <h4>{{ post.postedBy.display_name }}</h4>
          </div>
        </div>
  
          <div class="comment">
            <div class="post-content">
              <post-content [content]="post.text"> </post-content>
            </div>
            <div class="post-image row">
              <div class="col" *ngFor="let image of post.images">
                <img [src]="image" alt="Post image" />
              </div>
            </div>
          </div>  

            <div class="buttons-container">
              <div class="d-flex buttons">
                <button
                  (click)="addReaction('like')"
                  mat-button
                  class="attachment-button"
                >
                  <mat-icon>thumb_up</mat-icon>
                  <div class="count">
                    {{ post.reactionsCounts?.like || 0 }}
                  </div>
                </button>
                <button
                  mat-button
                  class="attachment-button"
                  (click)="showReplies = !showReplies"
                >
                  <mat-icon>chat_bubble</mat-icon>
                  <div class="count">
                    {{ post.commentCount || 0 }}
                  </div>
                </button>
                <button mat-button class="icon-button" (click)="share()">
                  <mat-icon style="font-size: 20px; font-weight: 500;">ios_share</mat-icon>
                </button>
              </div>
            </div>
          </div>
      </div>

</mat-card>
<div class="replies" [class.margin]="depth < 4">
    <post-replies [post]="post" *ngIf="showReplies" [depth]="depth + 1"></post-replies>
</div>