import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder } from '@angular/forms';
import { MatChipInputEvent, MatChipEditedEvent } from '@angular/material/chips';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'add-extra-section',
  templateUrl: './add-extra-section.component.html',
  styleUrls: ['./add-extra-section.component.scss'],
})
export class AddExtraSectionComponent implements OnInit{
  constructor(
    public fb: FormBuilder,
    public ref: MatDialogRef<AddExtraSectionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  public formGroup = this.fb.group({
    name: [''],
    type: [''],
    organization: [''],
    description: [''],
    talents: this.fb.array([]),
    startDate: [''],
    endDate: [''],
  });

  public get talents() {
    return this.formGroup.get('talents') as FormArray;
  }

  public get nameByType() {
    if (this.formGroup.value.type == 'education') {
      return 'Okul';
    }
    if (this.formGroup.value.type == 'experience') {
      return 'Kurum';
    }
    return 'Sertifika aldığınız kurum';
  }

  public get descriptionByType() {
    if (this.formGroup.value.type == 'education') {
      return 'Bölüm Adını Girin';
    }
    if (this.formGroup.value.type == 'experience') {
      return 'Çalıştığınız iş türünü girin';
    }
    return 'Sertifika ismini girin';
  }

  public submit() {
    this.ref.close(this.formGroup.value);
  }

  readonly separatorKeysCodes = [ENTER, COMMA] as const;

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our fruit
    if (value) {
      this.talents.push(this.fb.control(value));
    }

    // Clear the input value
    event.chipInput!.clear();
  }

  remove(location: string): void {
    const index = this.talents.controls.findIndex((x) => x.value === location);

    if (index >= 0) {
      this.talents.controls.splice(index, 1);
    }
  }

  edit(location: string, event: MatChipEditedEvent) {
    const value = event.value.trim();

    // Remove fruit if it no longer has a name
    if (!value) {
      this.remove(location);
      return;
    }

    // Edit existing fruit
    const index = this.talents.controls.findIndex(
      (item) => item.value === location
    );
    if (index >= 0) {
      this.talents.controls[index].patchValue(value);
    }
  }

  ngOnInit(): void {
    if (this.data) {
      this.formGroup.patchValue(this.data);
      for (const talent of this.data.talents) {
        this.talents.push(this.fb.control(talent));
      }
    }
  }
}
