<div class="panel" [formGroup]="profileEditing.formGroup">
  <h2>Öne Çıkarılan İçerik</h2>
  <div class="content">
    <div class="contents row justify-content-center">
      <mat-card
        class="content col-md-5"
        style="padding: 20px;"
        *ngFor="let content of featuredContent.controls; let i = index"
      >
        <div class="header d-flex justify-content-between align-items-center">
          <div class="title d-flex justify-content-between align-items-center">
            <h2>
              {{ content.value.contentType == "Post" ? "Gönderi" : "Etkinlik" }}
            </h2>
          </div>
          <div class="buttons d-flex justify-content-between">
            <a mat-icon-button (click)="removeFeaturedContent(i)">
              <mat-icon> delete </mat-icon>
            </a>
          </div>
        </div>
        <div class="img" *ngIf="content.value?.content?.images?.length">
          <media-image [fill]="true" appereance="rounded" [src]="content.value.content.images[0]" alt="" />
        </div>
        <div class="text mt-2">
          <p>
            {{ content.value.content.text }}
          </p>
        </div>
      </mat-card>

      <add-featured-content
        class="col-md-5"
        (onAdd)="profileEditing.addFeaturedContent($event)"
      ></add-featured-content>
    </div>
  </div>
</div>
