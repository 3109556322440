import { Component, Input, OnInit } from '@angular/core';
import { EventsService } from '@common/services/events.service';
import { PaginatorService } from '@common/services/paginator.service';
import { Event } from 'src/app/models/event';

@Component({
  selector: 'event-profile-participants',
  templateUrl: './event-profile-participants.component.html',
  styleUrls: ['./event-profile-participants.component.scss', '../panel.scss']
})
export class EventProfileParticipantsComponent implements OnInit{

  @Input() public event: Event;

  constructor(
    public paginator:PaginatorService,
    public eventsService:EventsService
  ) {}

  public get participants() {
    return this.event.participants?.slice(0, this.event.featuredParticipantCount);
  }

  ngOnInit(): void {
   
  }
}
