<ng-container *ngIf="youMightKnow$ | async as youMightKnow">
    <mat-card appearance="outlined" class="home-panel mat-elevation-z0">
      <div class="right-panel">
        <h2>
          {{
            auth.loggedIn
              ? "Tanıyor olabilirsin"
              : "Unilocked Topluluğunun Favorileri"
          }}
        </h2>
        <div
          style="cursor: pointer"
          *ngFor="let user of youMightKnow.items"
          [routerLink]="['/user-profile', user.name]"
        >
          <div class="d-flex">
            <img class="profile-image" [src]="user.avatar" alt="" />
            <div style="width: 100%">
              <div class="top">
                <h4 class="name">{{ user.display_name }}</h4>
              </div>
              <p class="info">{{ user.headnote }}</p>
            </div>
          </div>
          <div class="action">
            <button
              mat-flat-button
              style="width: 100%"
              class="follow"
              [class.button-primary]="!user.currentUserFollowed"
              [class.button-other]="user.currentUserFollowed"
              (click)="toggle($event, user)"
            >
              {{
                user.currentUserFollowed ? "Takibi Bırak" : "Takip Et"
              }}
              <mat-icon>
                {{
                  user.currentUserFollowed
                    ? "person_remove"
                    : "person_add"
                }}
              </mat-icon>
            </button>
          </div>
        </div>
        <div class="see-more">
          <a
            *ngIf="auth.loggedIn"
            [routerLink]="['/people']"
            routerLinkActive="router-link-active"
            mat-button
          >
            Daha fazlasını gör <mat-icon>chevron_right</mat-icon>
          </a>
        </div>
      </div>
    </mat-card>
  </ng-container>