import { Component } from '@angular/core';
import { ProfileEditingService } from '../../profile-editing.service';

@Component({
  selector: 'profile-editing-school-life',
  templateUrl: './profile-editing-school-life.component.html',
  styleUrls: ['./profile-editing-school-life.component.scss', '../panel.scss'],
})
export class ProfileEditingSchoolLifeComponent {
  constructor(public profileEditing: ProfileEditingService) {}
}
