export interface Contributor {
  id: any; // Replace 'any' with a more specific type if possible
  type: string;
}

export enum Visibility {
  'PUBLIC' = 'Herkese Açık',
  'FOLLOWERS' = 'Takipçiler Arası',
  'STUDENT-FOLLOWERS' = 'Takipçi Öğrenciler Arası',
  'PROFESSIONAL-FOLLOWERS' = 'Takipçi Profesyoneller Arası',
}

export enum NotifyOptions {
  'STUDENT-FOLLOWERS' = 'Takipçi Öğrenciler',
  'PROFESSIONAL-FOLLOWERS' = 'Takipçi Profesyoneller',
  'BUSINESS-PARTNERS' = 'İş Partnerleri',
}

export interface ImagesData {
  variants: [];
}
interface Image {
  id: string;
  imagesData: ImagesData;
}

interface Video {
  id: string;
}

export interface CreatePost {
  text: string;
  images: Image[];
  videos: Video[];
  visibility: Visibility;
  notify: string[];
  visibleFrom: string;
  visibleUntil: string;
  postLanguage: string;
  postLocation: string;
  contributors: Contributor[];
}
