import { PaginatorService } from '@common/services/paginator.service';
import { Component, Input, OnInit } from '@angular/core';
import { JobPostingService } from '@common/services/job-posting.service';
import { Company } from 'src/app/models/company';
import { JobPosting } from 'src/app/models/job-posting';
import { BehaviorSubject, map } from 'rxjs';
import { AuthService } from '@common/auth/auth.service';
import { FollowersService } from '@common/services/followers.service';

@Component({
  selector: 'company-job-postings',
  templateUrl: './company-job-postings.component.html',
  styleUrls: ['./company-job-postings.component.scss'],
})
export class CompanyJobPostingsComponent implements OnInit {
  @Input() company: Company;
  @Input() jobPostings: JobPosting[];
  @Input() members: any[];
  @Input() isMe: boolean;

  constructor(
    public jobPostingService: JobPostingService,
    public paginatorService: PaginatorService,
    public followersService: FollowersService,
    public auth: AuthService
  ) {}

  public key = 'jobPostings';
  ngOnInit(): void {
    this.key = this.jobPostings + '_' + this.company.id;
    this.paginatorService.init(this.key, (page) => {
      return this.jobPostingService
        .indexPostsOfProfile(this.company.id, page)
        .pipe(map((response) => response.data));
    });
    this.following$.next(this.auth.followedIds.includes(this.company.id));
  }

  public following$ = new BehaviorSubject<boolean>(false);
  public loading$ = new BehaviorSubject<boolean>(false);

  public toggle() {
    this.following$.next(!this.following$.value);
    this.loading$.next(true);
    this.followersService
      .toogleExtended(this.company, 'Company')
      .subscribe((res: any) => {
        console.log(res);
        this.loading$.next(false);
      });
  }
}
