import { Component } from '@angular/core';
import { FormArray, FormBuilder } from '@angular/forms';
import { ProfileEditingService } from '../../profile-editing.service';

@Component({
  selector: 'profile-editing-contact',
  templateUrl: './profile-editing-contact.component.html',
  styleUrls: ['./profile-editing-contact.component.scss', '../panel.scss'],
})
export class ProfileEditingContactComponent {
  constructor(
    public profileEditing: ProfileEditingService,
    private fb: FormBuilder
  ) {}

  public get contactInfo() {
    return this.formGroup.controls['contactList'] as FormArray;
  }

  public get formGroup() {
    return this.profileEditing.formGroup;
  }

  public addContactInfo() {
    const contactInfo = this.formGroup.get('addContactInfo');
    this.contactInfo.push(this.fb.control(contactInfo.value));
    this.formGroup.get('addContactInfo').reset();
  }

  public removeContactInfo(index) {
    this.contactInfo.removeAt(index);
  }
}
