<mat-card class="profile-card" *ngIf="paginator.paginations.get(key)">
    <div class="card-content">
      <div class="d-flex justify-content-between flex-wrap">
        <h1>Katılanlar</h1>
        <paginator [key]="key"></paginator>
      </div>
      <div class="participants row">
        <div class="col-sm-4" *ngFor="let participant of paginator.paginations.get(key).data">
          <div class="participant">
            <div class="image-container">
              <img [src]="participant.user.avatar" alt="Profile image" />
            </div>
            <div class="info-container">
              <h4 class="name">
                {{ participant.user.display_name || participant.user.name }}
              </h4>
              <p class="role">{{ participant.user.headnote }}</p>
            </div>
          </div>
          <div>
            <follow-button
              [profile]="participant.user"
              type="User"
            ></follow-button>
          </div>
        </div>
      </div>
    </div>
  </mat-card>
  