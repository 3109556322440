import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PostContentComponent } from './post/post-content/post-content.component';
import { PostComponent } from './post/post.component';
import { AppCommonModule } from '@common/common.module';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { WriteCommentComponent } from './write-comment/write-comment.component';
import { PostRepliesComponent } from './post-replies/post-replies.component';
import { PostReplyComponent } from './post-reply/post-reply.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import {MatDividerModule} from '@angular/material/divider';
import { MatMenuModule } from '@angular/material/menu';
import { PostVideoComponent } from './post/post-video/post-video.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';



@NgModule({
  declarations: [
    PostContentComponent,
    PostComponent,
    WriteCommentComponent,
    PostRepliesComponent,
    PostReplyComponent,
    PostVideoComponent
  ],
  imports: [
    CommonModule,
    AppCommonModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatDividerModule,
    MatMenuModule,
    NgxSkeletonLoaderModule
  ],
  exports: [
    PostComponent,
    WriteCommentComponent,
    PostRepliesComponent
  ]
})
export class PostsModule { }
