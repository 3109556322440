<div class="panel" [formGroup]="profileEditing.formGroup">
  <h2>Kurum Yaşamı</h2>
  <div class="content">
    <div>
      <div class="subtitle"></div>

      <text-editor-form class="st" formControlName="life"></text-editor-form>
    </div>
  </div>
</div>
