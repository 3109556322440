import { trigger, transition, style, animate } from '@angular/animations';
import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@common/auth/auth.service';
import { UploadService } from '@common/core/upload/upload.service';
import { ProfileService } from '@common/services/profile.service';
import { map, switchMap } from 'rxjs';
import MenuItem from '../../models/menu-item';
import { EditPanelContentDirective } from './edit-panel-content.directive';
import { EditPanelPreviewDirective } from './edit-panel-preview.directive';

@Component({
  selector: 'edit-panel',
  templateUrl: './edit-panel.component.html',
  styleUrls: ['./edit-panel.component.scss'],
  animations: [
    // Animasyonlu görünüm için bir trigger oluşturalım.
    trigger('fadeInOut', [
      // Görünüm geçişleri
      transition(':enter', [
        // Başlangıç stilimiz
        style({ opacity: 0 }),
        // Bitiş stilimiz
        animate('300ms ease-in-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        // Başlangıç stilimiz
        style({ opacity: 1 }),
        // Bitiş stilimiz
        animate('300ms ease-in-out', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class EditPanelComponent implements OnInit , OnChanges{
  panelOpenState = false;

  constructor(
    public uploadService: UploadService,
    public fb: FormBuilder,
    public route: ActivatedRoute,
    public profiles: ProfileService,
    public currentUser: AuthService
  ) {}

  @Output() save = new EventEmitter();
  @Input() contents = [];
  @Input() suggestedContents = [];
  @Input() title = 'Profil';
  @ContentChild(EditPanelContentDirective, { read: TemplateRef, static: true })
  template: TemplateRef<any>;
  @ContentChild(EditPanelPreviewDirective, { read: TemplateRef, static: true })
  preview: TemplateRef<any>;
  @Input() showPreview = true;
  @Input() loading = false;

  public editingTemplate: TemplateRef<any>;
  public previewTemplate: TemplateRef<any>;

  public submit() {
    this.save.emit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loading = changes['loading']?.currentValue;
  }

  accordion = [];
  activeButton = 1;
  bolum = false;
  duzenleme = true;
  onizle = false;

  bolumClick() {
    this.activeButton = 0;
    this.bolum = true;
    this.duzenleme = false;
    this.onizle = false;
    this.render();
  }

  duzenlemeClick() {
    this.activeButton = 1;
    this.bolum = false;
    this.duzenleme = true;
    this.onizle = false;
    this.render();
  }

  onizleClick() {
    this.activeButton = 2;
    this.bolum = false;
    this.duzenleme = false;
    this.onizle = true;
    this.render();
  }

  public render() {
    this.renderEditingTemplate();
    this.renderPreviewTemplate();
  }

  public renderEditingTemplate() {
    if (this.template && this.duzenleme) {
      if (this.editingTemplate == null) {
        this.editingTemplate = this.template;
      }
    } else {
      this.editingTemplate = null;
    }
  }

  public renderPreviewTemplate() {
    if (this.preview && this.onizle) {
      if (this.previewTemplate == null) {
        this.previewTemplate = this.preview;
      }
    } else {
      this.previewTemplate = null;
    }
  }

  ngOnInit(): void {
    this.render();
  }
  
}
