<ng-container *ngIf="event$ | async as event; else loading">
  <div class="main">
    <div class="container">
      <event-profile-header [event]="event"></event-profile-header>
      <event-profile-about [event]="event"></event-profile-about>
      <event-profile-featured-content
        [event]="event"
      ></event-profile-featured-content>
      <event-profile-applications [event]="event"></event-profile-applications>
      <event-profile-sections [event]="event"></event-profile-sections>
      <event-profile-participants [event]="event"></event-profile-participants>
      <event-profile-tickets [event]="event"></event-profile-tickets>
      <event-profile-contact [event]="event"></event-profile-contact>
    </div>
  </div>
</ng-container>
<ng-template #loading>
  <div class="loading">
    <center-loading></center-loading>
  </div>
</ng-template>
