<mat-card class="profile-card">
  <div class="card-content">
    <h1>Etkinlik Programı</h1>
    <div class="contents">
      <div class="days">
        <ng-container *ngFor="let day of dayArray; let dayIndex = index">
          <div class="day" *ngIf="filter(event.sections, dayIndex).length > 0">
            <h3 class="dayName">{{ day | dateDayMonth }}</h3>
            <div class="row">
              <div
                class="content"
                *ngFor="
                  let section of filter(event.sections, dayIndex);
                  let i = index
                "
                [class.col-sm-8]="i != 0 && i % 4 === 0"
                [class.col-sm-4]="i == 0 || i % 4 !== 0"
                [class.hasBorder]="i != 0 && i % 4 === 0"
              >
                <div class="top">
                  <h1 class="hour" [style.color]="accentColor">
                    {{ section.startTime | dateHour }}
                  </h1>
                  <h4 class="name">{{ section.name }}</h4>
                  <p class="description">{{ section.description }}</p>
                  <p class="duration">{{ section.duration }} dakika</p>
                </div>
                <div class="participants">
                  <div class="participant" *ngFor="let participant of section.participants">
                    <img
                      [src]="participant.user.avatar"
                     
                    />
                    <div class="texts">
                      <p class="name">{{participant.user.display_name || participant.user.name}}</p>
                      <p class="role">{{participant.role.display_name}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
       
      </div>
    </div>
  </div>
</mat-card>
