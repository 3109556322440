import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { JobPostingService } from '@common/services/job-posting.service';
import { BehaviorSubject, map, switchMap } from 'rxjs';
import { JobPosting } from 'src/app/models/job-posting';

@Component({
  selector: 'job-posting-details',
  templateUrl: './job-posting-details.component.html',
  styleUrls: ['./job-posting-details.component.scss'],
})
export class JobPostingDetailsComponent implements OnChanges, OnInit {
  @Input() public jobPosting: JobPosting;
  public navbar = false

  subscription: any;

  constructor(
    public jobPostingService: JobPostingService,
    public activatedRoute: ActivatedRoute
  ) {}

  public data$ = this.activatedRoute.params
    .pipe(
      switchMap((params) => {
        if (this.jobPosting) {
          return new BehaviorSubject({ data: this.jobPosting });
        }
        this.navbar = true
        return this.jobPostingService.getJobPosting(params['id']);
      })
    )
    .pipe(map((response) => response.data));

  ngOnChanges(changes: SimpleChanges) {
    this.jobPosting = changes['jobPosting'].currentValue;
  }

  ngOnInit(): void {
    this.subscription = this.data$.subscribe((data) => {
      this.jobPosting = data;
    });
  }
}
