<div class="panel" [formGroup]="profileEditing.formGroup">
<div class="content">
    <div>
      <div class="subtitle">
        <h2>Hakkında</h2>
        <p></p>
      </div>

      <text-editor-form formControlName="about"></text-editor-form>
    </div>
  </div>
</div>
