<mat-card class="profile-card">
    <div class="card-content">
        <h1>Zaman Çizelgesi</h1>
        <div class="timeline-container">
            <div class="timeline">
                <div class="timeline-item" *ngFor="let section of project.timeline">
                    <div class="timeline-item-content">
                        <div class="timeline-item-header">
                            <h3>{{ section.date | dateLocale }}</h3>
                            <p>{{ section.description }}</p>
                        </div>
                       <div class="timeline-images row">
                            <div class="image col-sm-4" *ngFor="let image of section.images">
                                <img [src]="image.imagesData.variants[0]" alt="Timeline image" />
                            </div>
                       </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</mat-card>