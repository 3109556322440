import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventsService } from '@common/services/events.service';
import { BehaviorSubject, map, switchMap } from 'rxjs';
import { Event } from 'src/app/models/event';

@Component({
  selector: 'event-profile',
  templateUrl: './event-profile.component.html',
  styleUrls: ['./event-profile.component.scss'],
})
export class EventProfileComponent {
  constructor(
    private events: EventsService,
    private activatedRoute: ActivatedRoute
  ) {}

  @Input() preview: Event;

  public event$ = this.activatedRoute.params
    .pipe(
      switchMap((params) => {
        if (this.preview) {
          return new BehaviorSubject({ data: this.preview });
        }
        return this.events.get(params['id']);
      })
    )
    .pipe(map((response) => response.data));
}
