<div class="content">
  <form [formGroup]="form">
    <div class="title">
      <h1>Yeni Görüşme</h1>
    </div>
    <div class="type">
      <mat-chip-listbox formControlName="type">
        <mat-chip-option value="dm" selected="">Özel Görüşme</mat-chip-option>
        <mat-chip-option value="group">Grup Görüşmesi</mat-chip-option>
  
      </mat-chip-listbox>
    </div>
    <div class="name" *ngIf="form.value.type == 'group'">
      <mat-form-field style="min-width: 400px;" appearance="outline">
        <mat-label>Görüşme Adı</mat-label>
        <input [required]="form.value.type=='group'" matInput formControlName="name" />
      </mat-form-field>
    </div>
    <div class="profiles">
      <mat-form-field style="min-width: 400px;" appearance="outline">
        <mat-label>Eklenecek Kişi</mat-label>
        <input formControlName="user" matInput [matAutocomplete]="auto" (keyup.enter)="addUser()"/>
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="getName">
          <mat-option
            *ngFor="let option of avaibleContacts$ | async"
            [value]="option"
          >
            {{ option.display_name || option.name}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <div class="chips">
        <mat-chip-set>
          <mat-chip *ngFor="let user of users.controls; let i = index;" (removed)="removeUser(i)">
            {{ user.value.display_name || user.value.name }}
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>
        </mat-chip-set>
      </div>
    </div>
    <div class="start">
      <button mat-flat-button color="primary" type="button" class="button-primary" (click)="openRoom()">Görüşmeyi Başlat</button>
    </div>
  </form>
</div>