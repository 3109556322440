import { Injectable } from '@angular/core';
import { AppHttpClient } from '@common/core/http/http.service';
import { StandartResponse } from '@common/core/http/standart-response';

const BASE = 'featured-content';

@Injectable({
  providedIn: 'root',
})
export class FeaturedContentService {
  constructor(private http: AppHttpClient) {}

  public resolveFeaturedContent(url: string) {
    return this.http.post<StandartResponse<any>>(
      `${BASE}/resolve-featured-content`,
      {
        url,
      }
    );
  }
}
