import { ProfileEditingFeaturedContentComponent } from './components/profile-editing-featured-content/profile-editing-featured-content.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileEditingComponent } from './profile-editing.component';
import { ProfileEditingAboutComponent } from './components/profile-editing-about/profile-editing-about.component';
import { ProfileEditingContactComponent } from './components/profile-editing-contact/profile-editing-contact.component';
import { ProfileEditingExtraSectionsComponent } from './components/profile-editing-extra-sections/profile-editing-extra-sections.component';
import { ProfileEditingGeneralComponent } from './components/profile-editing-general/profile-editing-general.component';
import { ProfileEditingSchoolLifeComponent } from './components/profile-editing-school-life/profile-editing-school-life.component';
import { AppCommonModule } from '@common/common.module';
import { MaterialDesignModule } from '@common/material-design/material-design.module';
import { TextEditorFormComponent } from '@common/text-editor/text-editor-form/text-editor-form.component';
import { TextEditorComponent } from '@common/text-editor/text-editor.component';
import { TextEditorModule } from '@common/text-editor/text-editor.module';
import { ReactiveFormsModule } from '@angular/forms';
import { EditPanelModule } from '../components/edit-panel/edit-panel.module';
import { MatExpansionModule } from '@angular/material/expansion';
import { EditProfileFieldsComponent } from './panels/edit-profile-fields/edit-profile-fields.component';
import { AddFeaturedContentComponent } from '../components/add-featured-content/add-featured-content.component';
import { AddFeaturedContentModule } from '../components/add-featured-content/add-featured-content.module';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

@NgModule({
  declarations: [
    ProfileEditingAboutComponent,
    ProfileEditingContactComponent,
    ProfileEditingExtraSectionsComponent,
    ProfileEditingFeaturedContentComponent,
    ProfileEditingGeneralComponent,
    ProfileEditingSchoolLifeComponent,
    EditProfileFieldsComponent,
  ],
  imports: [
    CommonModule,
    AppCommonModule,
    MaterialDesignModule,
    TextEditorModule,
    ReactiveFormsModule,
    EditPanelModule,
    MatExpansionModule,
    AddFeaturedContentModule,
  ],
  exports: [
    ProfileEditingAboutComponent,
    ProfileEditingContactComponent,
    ProfileEditingExtraSectionsComponent,
    ProfileEditingFeaturedContentComponent,
    ProfileEditingGeneralComponent,
    ProfileEditingSchoolLifeComponent,
    EditProfileFieldsComponent,
  ],
})
export class ProfileEditingModule {}
