<div class="panel" [formGroup]="general">
  <h2>Genel Bilgiler</h2>
  <div class="content">
    <div>
      <div class="subtitle">
        <h3>Profil Fotoğrafı</h3>
        <p>Profilinizde görünecek profil fotoğrafınızı ayarlayın.</p>
      </div>
      <div class="photo d-flex st">
        <media-image
          width="100"
          height="100"
          aspectRatio="1/1"
          appereance="rounded"
          class="avatar"
          *ngIf="formGroup.value.general.avatarUrl"
          [src]="formGroup.value.general.avatarUrl"
        />
        <div class="changephoto">
          <b *ngIf="formGroup.value.general.avatarUrl">Kullanılan Fotoğraf</b>
          <p>
            Yüklenecek fotoğrafın en az 400x400px ve jpg/png formatında olması
            gerekmektedir. 1:1 fotoğraf oranı tercih edilmelidir.
          </p>
          <div class="buttons d-flex flex-wrap">
            <button
              type="button"
              mat-flat-button
              class="upload"
              (click)="uploadAvatar()"
            >
              Fotoğraf Yükle
            </button>
            <button
              type="button"
              *ngIf="formGroup.value.general.avatarUrl"
              mat-flat-button
              class="remove"
              (click)="removeAvatar()"
            >
              Fotoğraf Kaldır
            </button>
          </div>
        </div>
      </div>
    </div>
    <div style="margin-top: 4%">
      <div class="subtitle st">
        <h3>Kısa Özet</h3>
        <p>Profilinizi özetleyen bir kaç kelimelik bir metin girin.</p>
      </div>
      <div class="input">
        <mat-form-field class="ff st">
          <mat-label>Kısa Özet</mat-label>
          <input matInput formControlName="headnote" />
        </mat-form-field>
      </div>
    </div>
    <div>
      <div class="subtitle">
        <h3>Arka Plan Fotoğrafı</h3>
        <p>Profilinizde arka planda görünecek fotoğrafınızı ayarlayın.</p>
      </div>
      <div class="photo d-flex flex-column st">
        <media-image
          class="backdrop"
          [fill]="true" 
          aspectRatio="16/3"
          [priority]="true"
          *ngIf="formGroup.value.general.backdropUrl"
          [src]="formGroup.value.general.backdropFile ||  formGroup.value.general.backdropUrl"
        />
        <div class="changephoto">
          <p>
            Yüklenecek fotoğrafın en az 800x450px ve jpg formatında olması
            gerekmektedir. 16:3 fotoğraf oranı tercih edilmelidir.
          </p>
          <div class="buttons d-flex flex-wrap">
            <button
              type="button"
              mat-flat-button
              class="upload"
              (click)="uploadBackdrop()"
            >
              Fotoğraf Yükle
            </button>
            <button
              type="button"
              *ngIf="formGroup.value.general.avatarUrl"
              mat-flat-button
              class="remove"
              (click)="uploadBackdrop()"
            >
              Fotoğraf Kaldır
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
