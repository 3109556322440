import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditPanelComponent } from './edit-panel.component';
import { OrderSectionsComponent } from '../order-sections/order-sections.component';
import { MatButtonModule } from '@angular/material/button';
import { ReactiveFormsModule } from '@angular/forms';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { EditProfileFieldsComponent } from '../../profile-editing/panels/edit-profile-fields/edit-profile-fields.component';
import { AppCommonModule } from '@common/common.module';

@NgModule({
  declarations: [EditPanelComponent, OrderSectionsComponent],
  imports: [CommonModule,AppCommonModule, MatButtonModule, ReactiveFormsModule, MatTabsModule, MatIconModule,DragDropModule],
  exports: [EditPanelComponent, OrderSectionsComponent],
})
export class EditPanelModule {}
