<div class="replies">
  <write-comment [post]="post" (replyAdded)="addReply($event)"></write-comment>
  <div class="replies-container">
    <ng-container *ngIf="replies$ | async as replies">
      <post-reply
        [depth]="depth"
        *ngFor="let reply of replies"
        [post]="reply"
      ></post-reply>
      <div class="hasMore" *ngIf="hasMore$ | async as hasMore">
        <button
          *ngIf="hasMore"
          mat-flat-button
          (click)="loadMore()"
          class="button-other"
        >
          Daha fazla yanıt yükle
        </button>
      </div>
    </ng-container>
  </div>
</div>
<ng-container *ngIf="loading$ | async">
  <div class="loading" style="width: 100%">
    <div
      class="d-flex"
      style="gap: 20px; width: 100%; margin-top: 10px"
      *ngFor="let _ of [].constructor(5)"
    >
      <ngx-skeleton-loader
        [theme]="{ height: '50px', width: '50px' }"
        appearance="circle"
      ></ngx-skeleton-loader>
      <ngx-skeleton-loader
        style="width: 100%"
        [theme]="{ height: '50px', width: '100%' }"
      ></ngx-skeleton-loader>
    </div>
  </div>
</ng-container>
