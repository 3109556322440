import { Injectable } from '@angular/core';
import { AppHttpClient } from '@common/core/http/http.service';
import { StandartResponse } from '@common/core/http/standart-response';
import { Community } from 'src/app/models/community';
import { JobPosting } from 'src/app/models/job-posting';
import { Post } from 'src/app/models/post';
import { Members } from 'src/app/models/relations/members';

const BASE = 'communities';

@Injectable({
  providedIn: 'root',
})
export class CommunitiesService {
  constructor(private http: AppHttpClient) {}

 
  public getHome() {
    return this.http.get<StandartResponse<CommunitiesHomeData>>(BASE);
  }

  public getCommunity(id: string) {
    return this.http.get<
      StandartResponse<{
        community: Community;
        members: Members[];
        jobPostings: JobPosting[];
        posts: Post[];
      }>
    >(`${BASE}/${id}`);
  }

  public getFollowing() {
    return this.http.get<StandartResponse<Community[]>>(BASE + '/followed');
  }

  public getJoined() {
    return this.http.get<StandartResponse<Community[]>>(BASE + '/joined');
  }
}

export interface CommunitiesHomeData {
  joined: Community[];
  suggestions: Community[];
  followed: Community[];
}
