import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import {  RouterModule } from '@angular/router';
import { AuthService } from '@common/auth/auth.service';
import { FollowersService } from '@common/services/followers.service';
import { HomeService } from '@common/services/home.service';
import { map } from 'rxjs';

@Component({
  selector: 'youmightknow',
  templateUrl: './youmightknow.component.html',
  styleUrls: ['./youmightknow.component.scss'],
  standalone: true,
  imports: [RouterModule,MatCardModule,CommonModule,MatIconModule,MatButtonModule]
})
export class YoumightknowComponent {
  public youMightKnow$ = this.homeService
  .youMightKnow()
  .pipe(map((res) => res.data));
  
  constructor(private homeService: HomeService,
    public auth: AuthService,
    private followerService: FollowersService
  ) {}

  public toggle($event, user) {
    $event.stopPropagation();
    this.followerService.toogleExtended(user).subscribe();
  }
}
