<mat-card class="post-card hover-effect-card" [ngClass]="getStatusColor()">
  <div class="title d-flex justify-content-between align-items-center">
    <span class="time d-flex align-items-center">
      <mat-icon> {{getStatusIcon()}} </mat-icon>
      {{post.visibleFrom | dateHour}}
    </span>
    <mat-icon> more_horiz </mat-icon>
  </div>
  <div class="subtitle">
    {{getStatusText()}}<br />
    <span [innerHTML]="post.text.slice(0,20)"></span>
  </div>
</mat-card>
