<div class="row" *ngIf="!loading">
  <div
    *ngFor="let mission of missions"
    class="col-4"
    [class.col-md-6]="missions.length > 0"
  >
    <div class="card">
      <div class="row">
        <div class="col-12">
          <div class="d-flex align-items-center justify-content-between">
            <span
              ><strong>{{ mission.description }}</strong></span
            >
            <div [ngSwitch]="mission.status">
              <div *ngSwitchCase="'completed'" class="status">
                <div class="point success"></div>
                <span>Tamamlandı</span>
              </div>
              <div *ngSwitchCase="'active'" class="status">
                <div class="point processing"></div>
                <span>Devam Ediyor</span>
              </div>
              <div *ngSwitchCase="'failed'" class="status">
                <div class="point canceled"></div>
                <span>Tamamlanamadı</span>
              </div>
              <div *ngSwitchCase="'cancelled'" class="status">
                <div class="point canceled"></div>
                <span>İptal Edildi</span>
              </div>
              <div *ngSwitchCase="'paused'" class="status">
                <div class="point canceled"></div>
                <span>Duraklatıldı</span>
              </div>
            </div>
          </div>
        </div>
        <div
          class="mt-4 col-12 d-flex justify-content-start align-items-center gap-3"
        >
          <div class="avatars">
            <a
              *ngFor="let member of mission.members"
              href="#"
              class="avatars__item"
              ><img class="avatar" [src]="member.avatar" alt=""
            /></a>
          </div>
          <span style="margin-left: 10px"
            >{{ isUserIncluded() ? "siz" : "" }}
            {{ mission.members.length }} kişi atandı</span
          >
        </div>
        <div *ngIf="isUserIncluded()" class="col-12 mt-4">
          <button
            [disabled]="mission.status !== 'active'"
            mat-flat-button
            color="accent"
            style="width: 100%"
            class="button-other"
          >
            Görevi Teslim Et
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row" *ngIf="loading">
  <div class="col-md-6" *ngFor="let _ of [].constructor(6)">
    <ngx-skeleton-loader
      [count]="1"
      [theme]="{
        height: '150px',
        'border-radius': '8px',
        'margin-bottom': '16px',
      }"
    ></ngx-skeleton-loader>
  </div>
</div>
