import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CalendarListComponent } from './calendar-list/calendar-list.component';
import { CalendarWeekComponent } from './calendar-week/calendar-week.component';
import { CalendarMonthComponent } from './calendar-month/calendar-month.component';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { AppCommonModule } from '@common/common.module';
import { PostCalendarListItemComponent } from './components/post-calendar-list-item/post-calendar-list-item.component';
import { PostCalendarWeekItemComponent } from './components/post-calendar-week-item/post-calendar-week-item.component';
import { CalendarEventListItemComponent } from './components/calendar-event-list-item/calendar-event-list-item.component';
import { CalendarEventWeekItemComponent } from './components/calendar-event-week-item/calendar-event-week-item.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    CalendarListComponent,
    CalendarWeekComponent,
    CalendarMonthComponent,
    PostCalendarListItemComponent,
    PostCalendarWeekItemComponent,
    CalendarEventListItemComponent,
    CalendarEventWeekItemComponent,
  ],
  imports: [
    CommonModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    AppCommonModule,
    RouterModule,
  ],
  exports: [
    CalendarListComponent,
    CalendarWeekComponent,
    CalendarMonthComponent,
  ],
})
export class CalendarModule {}
