import { Component, Input } from '@angular/core';
import { Post } from 'src/app/models/post';

@Component({
  selector: 'post-calendar-week-item',
  templateUrl: './post-calendar-week-item.component.html',
  styleUrls: ['./post-calendar-week-item.component.scss'],
})
export class PostCalendarWeekItemComponent {
  @Input() post: Post;

  public getStatusColor() {
    const dateDiffrence =
      new Date(this.post.visibleFrom).getTime() - new Date().getTime();
    if (dateDiffrence < 0) {
      return 'success';
    } else if (dateDiffrence < 1000 * 60 * 60 * 24 * 7) {
      return 'info';
    } else {
      return 'info';
    }
  }

  public getStatusText() {
    const dateDiffrence =
      new Date(this.post.visibleFrom).getTime() - new Date().getTime();
    if (dateDiffrence < 0) {
      return 'Yayınlandı';
    } else if (dateDiffrence < 1000 * 60 * 60 * 24 * 7) {
      return 'Planlandı';
    } else {
      return 'Planlandı';
    }
  }

  public getStatusIcon() {
    const dateDiffrence =
      new Date(this.post.visibleFrom).getTime() - new Date().getTime();
    if (dateDiffrence < 0) {
      return 'check';
    } else if (dateDiffrence < 1000 * 60 * 60 * 24 * 7) {
      return 'schedule';
    } else {
      return 'schedule';
    }
  }

  public getVisibilityText() {
    if (this.post.visibility == 'PUBLIC') {
      return 'Herkese Açık';
    } else if (this.post.visibility == 'FOLLOWERS') {
      return 'Takipçilere Açık';
    } else if (this.post.visibility == 'PROFESSIONAL-FOLLOWERS') {
      return 'Profesyonel Takipçilere Açık';
    } else {
      return 'Öğrencilere Açık';
    }
  }
}
