import { Component } from '@angular/core';
import { ProfileEditingService } from '../../profile-editing.service';

@Component({
  selector: 'profile-editing-about',
  templateUrl: './profile-editing-about.component.html',
  styleUrls: ['./profile-editing-about.component.scss','../panel.scss'],
})
export class ProfileEditingAboutComponent {
  constructor(public profileEditing: ProfileEditingService) {}
}
