import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'order-sections',
  templateUrl: './order-sections.component.html',
  styleUrls: ['./order-sections.component.scss'],
})
export class OrderSectionsComponent {
  @Input() public contents = [
    {
      title: 'Hakkında',
      subtitle: 'yes',
    },
    {
      title: 'Gönderiler',
      subtitle: 'yes',
    },
    {
      title: 'İletişim',
      subtitle: 'no',
    },
    {
      title: 'Öne Çıkarılan İçerikler',
      subtitle: 'yes',
    },
    {
      title: 'Kurumda Yaşam',
      subtitle: 'yes',
    },
    {
      title: 'Güncel Staj İlanları',
      subtitle: 'yes',
    },
    {
      title: 'Mezunlar',
      subtitle: 'yes',
    },
  ];

  @Input() public suggested = [
    {
      title: 'Okul Toplulukları',
      subtitle: 'Kurumunuzun altında bulunan toplulukları gösterin.',
    },
    {
      title: 'Okuldan Projeler',
      subtitle:
        'Okul Toplulukları veya anlaşmalı kurumların projelerini gösterin. • Proje yönetimini İş Birliği Platformu üzerinden düzenleyebilirsiniz.',
    },
    {
      title: 'Etkinlikler',
      subtitle:
        'Düzenlediğiniz veya kurumunuz altında düzenlenen etkinlikleri listeleyin. • Etkinlik yönetimini İş Birliği Platformu üzerinden düzenleyebilirsiniz.',
    },
    {
      title: 'Teknopark',
      subtitle:
        'Kurumunuz altında bulunan veya anlaşmalı olduğunuz Teknoparkın profiline yönlendirin.',
    },
  ];

  public move(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.contents, event.previousIndex, event.currentIndex);
  }
}
