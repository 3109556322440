import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from '@common/auth/auth.service';
import { ProjectsService } from '@common/services/projects.service';
import { Project } from 'src/app/models/project';

@Component({
  selector: 'project-missions',
  templateUrl: './project-missions.component.html',
  styleUrls: ['./project-missions.component.scss'],
})
export class ProjectMissionsComponent implements OnInit {
  @Input() projectId: string;
  missions: any[] = [];
  userInformation: any;

  public loading = true;

  constructor(
    private projects: ProjectsService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.getMissions();
    this.getUserInformation();
  }

  getMissions() {
    this.projects.getMissions(this.projectId, 1).subscribe({
      next: (res: any) => {
        this.missions = res.data.data;
        this.loading = false;
      },
      error: (err: HttpErrorResponse) => {
        console.error('Görevler Alınamadı', err.error);
      },
    });
  }
  getUserInformation() {
    this.authService.user$.subscribe((user) => {
      this.userInformation = user;
    });
  }

  isUserIncluded(): boolean {
    return this.missions.some((mission) =>
      mission.members.some((member) => member.id === this.userInformation.id)
    );
  }
}
