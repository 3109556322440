import { Component, Input } from '@angular/core';
import { Event } from 'src/app/models/event';

@Component({
  selector: 'calendar-event-week-item',
  templateUrl: './calendar-event-week-item.component.html',
  styleUrls: ['./calendar-event-week-item.component.scss'],
})
export class CalendarEventWeekItemComponent {
  @Input() item: Event;

  public getStatusColor() {
    const dateDiffrence =
      new Date(this.item.startDate).getTime() - new Date().getTime();
    if (dateDiffrence < 0) {
      return 'success';
    } else if (dateDiffrence < 1000 * 60 * 60 * 24 * 7) {
      return 'info';
    } else {
      return 'info';
    }
  }

  public getStatusText() {
    const dateDiffrence =
      new Date(this.item.startDate).getTime() - new Date().getTime();
    if (dateDiffrence < 0) {
      return 'Yayınlandı';
    } else if (dateDiffrence < 1000 * 60 * 60 * 24 * 7) {
      return 'Planlandı';
    } else {
      return 'Planlandı';
    }
  }

  public getStatusIcon() {
    const dateDiffrence =
      new Date(this.item.startDate).getTime() - new Date().getTime();
    if (dateDiffrence < 0) {
      return 'check';
    } else if (dateDiffrence < 1000 * 60 * 60 * 24 * 7) {
      return 'schedule';
    } else {
      return 'schedule';
    }
  }

  public getVisibilityText() {
    return '';
  }
}
