import { EventSection } from '../../../../../../models/relations/events/event-section';
import { Component, Input, OnInit } from '@angular/core';
import { eventColors } from '@common/core/utils/event-colors';
import { Event } from 'src/app/models/event';

@Component({
  selector: 'event-profile-sections',
  templateUrl: './event-profile-sections.component.html',
  styleUrls: ['./event-profile-sections.component.scss', '../panel.scss'],
})
export class EventProfileSectionsComponent implements OnInit {
  @Input() public event: Event;

  public get accentColor() {
    return eventColors.find((color) => color.value === this.event.themeColor)
      ?.colors[0];
  }

  public dayArray = []

  public getDayArray() {
    const startDate = this.event.startDate;
    const endDate = this.event.endDate;
    const start = new Date(startDate);
    const end = new Date(endDate);
    start.setHours(0, 0, 0, 0);
    end.setHours(23, 59, 59, 999);
    const dayArray = [];
    // limit to 30 days
    if (end.getTime() - start.getTime() > 30 * 24 * 60 * 60 * 1000) {
      end.setTime(start.getTime() + 30 * 24 * 60 * 60 * 1000);
    }
    for (let d = start; d <= end; d.setDate(d.getDate() + 1)) {
      dayArray.push(new Date(d));
    }
    console.log(dayArray)
    return dayArray;
  }

  public startTime(section:EventSection) {
    console.log(section.startTime)
    return new Date(section.startTime)
  }

  public filter(sections: EventSection[], day: number) {
    return sections.filter((section) => {
      return section.day == (day + 1);
    });
  }

  ngOnInit(): void {
    this.dayArray = this.getDayArray()
  }
}
