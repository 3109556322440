import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'post-content',
  templateUrl: './post-content.component.html',
  styleUrls: ['./post-content.component.scss'],
})
export class PostContentComponent implements OnInit {
  @Input('content') content: string;

  public parsedContent: string[] = [];
  public getParsedContent() {
    // check content includes html
    const html = this.content.includes('<');
    if (html) {
      return [this.content];
    } else {
      return this.content.split(' ');
    }
  }

  public isHashtag(word) {
    return word.startsWith('#');
  }

  constructor() {}

  ngOnInit(): void {
    this.parsedContent = this.getParsedContent();
  }
}
