import { Component } from '@angular/core';
import { FormBuilder, FormArray } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AddExtraSectionComponent } from 'src/app/admin/components/add-extra-section/add-extra-section.component';
import { ProfileEditingService } from '../../profile-editing.service';

@Component({
  selector: 'profile-editing-extra-sections',
  templateUrl: './profile-editing-extra-sections.component.html',
  styleUrls: [
    './profile-editing-extra-sections.component.scss',
    '../panel.scss',
  ],
})
export class ProfileEditingExtraSectionsComponent {
  constructor(
    public profileEditing: ProfileEditingService,
    private fb: FormBuilder,
    public dialog: MatDialog
  ) {}

  public get contactInfo() {
    return this.formGroup.controls['extraSections'] as FormArray;
  }

  public get formGroup() {
    return this.profileEditing.formGroup;
  }

  public addContactInfo() {
    const ref = this.dialog.open(AddExtraSectionComponent);
    ref.afterClosed().subscribe((result) => {
      if (result) {
        this.contactInfo.push(this.fb.control(result));
      }
    });
  }

  public editContactInfo(index) {
    const ref = this.dialog.open(AddExtraSectionComponent, {
      data: this.contactInfo.controls[index].value,
    });
    ref.afterClosed().subscribe((result) => {
      if (result) {
        this.contactInfo.controls[index].setValue(result);
      }
    });
  }

  public removeContactInfo(index) {
    this.contactInfo.removeAt(index);
  }
}
