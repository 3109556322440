<mat-card class="profile-card">
  <div class="header-image">
    <img [src]="event.coverImage.imagesData.variants[0]" alt="Cover" />
  </div>
  <div class="colors">
    <div
      class="color"
      *ngFor="let color of colors.colors"
      [style.backgroundColor]="color"
    ></div>
  </div>
  <div class="card-content">
    <div class="header">
      <h1>{{ event.name }}</h1>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <div class="description">
          <p>{{ event.headnote }}</p>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="creater">
          <div class="logos">
            <img
              *ngFor="let contributor of event.contributors"
              [src]="contributor.profile.avatar"
            />
          </div>
          <p>
            <b *ngFor="let contributor of event.contributors; let i = index"
              >{{ contributor.profile.display_name || contributor.profile.name }}
              <ng-container *ngIf="i < event.contributors.length - 1"
                >,
              </ng-container></b
            >
            tarafından düzenleniyor
          </p>
        </div>
        <div class="date">
          <mat-icon>calendar_month</mat-icon>
          <p>{{ event.startDate | dateDayMonth }}</p>
        </div>
        <div class="time">
          <mat-icon>schedule</mat-icon>
          <p>{{ event.startDate | dateHour }}</p>
        </div>
        <div class="location">
          <mat-icon>location_on</mat-icon>
          <p>{{ event.location }}</p>
        </div>

      </div>
     
    </div>
    <div class="header-bottom flex-wrap" >
        <p>Unilocked sanal bilet</p>
        <div class="buttons">
            <button *ngIf="isJoined" mat-stroked-button color="primary">
                <mat-icon>done</mat-icon>
                Katılıyorsunuz
            </button>
            
        </div>
    </div>
  </div>
</mat-card>
