import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '@common/auth/auth.service';
import { StandartResponse } from '@common/core/http/standart-response';
import { AddEventDialogComponent } from '@common/ui/add-event-dialog/add-event-dialog.component';
import { BehaviorSubject, switchMap, map } from 'rxjs';
import { EventCalendarNote } from 'src/app/models/relations/events/event-calendar-note';
import { AddCalendarNoteDialogComponent } from '../../events/event-center/components/add-calendar-note-dialog/add-calendar-note-dialog.component';
import { ViewCalendarNoteDialogComponent } from '../../events/event-center/components/view-calendar-note-dialog/view-calendar-note-dialog.component';

@Component({
  selector: 'calendar-week',
  templateUrl: './calendar-week.component.html',
  styleUrls: ['./calendar-week.component.scss'],
})
export class CalendarWeekComponent {
  constructor(public dialog: MatDialog, public auth: AuthService) {}

  public isPost = (item: any) => item.type === 'post' || item?.text;
  public isEvent = (item: any) => item.type === 'event' || item?.startDate;

  public dataObserver$: any = new BehaviorSubject((date: Date) => {
    return new BehaviorSubject<
      StandartResponse<
        {
          date: Date;
          items: any[];
        }[]
      >
    >(null);
  });

  public openCreateEventDialog() {
    this.dialog.open(AddEventDialogComponent, {
      panelClass: 'add-event-dialog',
    });
  }

  public getViewAnalyticsText(percentage: string) {
    // ex: +100
    if (percentage.startsWith('+')) {
      return 'Etkinlikleriniz önceki haftaya göre daha çok görüntülendi.';
    } else {
      return 'Etkinlikleriniz önceki haftaya göre daha az görüntülendi.';
    }
  }

  public getTicketAnalyticsText(percentage: string) {
    // ex: +100
    if (percentage.startsWith('+')) {
      return 'Etkinliklerinize katılım önceki haftaya kıyasla arttı.';
    } else {
      return 'Etkinliklerinize katılım önceki haftaya kıyasla azaldı.';
    }
  }

  public currentDate = new BehaviorSubject(new Date());
  public loading$ = new BehaviorSubject(true);

  public get firstDayOfWeek() {
    const date = this.currentDate.value;
    const day = date.getDay();
    const diff = date.getDate() - day + (day == 0 ? -6 : 1);
    return new Date(date.setDate(diff));
  }

  public week$ = this.currentDate
    .pipe(
      switchMap((date) => {
        return this.dataObserver$;
      })
    )
    .pipe(switchMap((observer: any) => observer(this.firstDayOfWeek)))
    .pipe(
      map((data: any) => {
        this.loading$.next(false);
        return data?.data || [];
      })
    );

  public get lastDayOfWeek() {
    const curr = this.currentDate.value;
    const lastday = new Date(curr.setDate(curr.getDate() - curr.getDay() + 6));
    return lastday;
  }

  public getDaysOfWeek() {
    return [
      'Pazartesi',
      'Salı',
      'Çarşamba',
      'Perşembe',
      'Cuma',
      'Cumartesi',
      'Pazar',
    ];
  }

  public next() {
    this.currentDate.value.setDate(this.currentDate.value.getDate() + 7);
    this.currentDate.next(this.currentDate.value);
    this.loading$.next(true);
  }

  public prev() {
    this.currentDate.value.setDate(this.currentDate.value.getDate() - 7);
    this.currentDate.next(this.currentDate.value);
    this.loading$.next(true);
  }

  public viewNote(note: EventCalendarNote) {
    const ref = this.dialog.open(ViewCalendarNoteDialogComponent, {
      panelClass: 'add-event-dialog',
      data: note,
    });
    ref.afterClosed().subscribe((data: any) => {
      if (data) {
        this.currentDate.next(this.currentDate.value);
        this.loading$.next(true);
      }
    });
  }

  public addNote(date: Date) {
    date = new Date(date);
    const ref = this.dialog.open(AddCalendarNoteDialogComponent, {
      panelClass: 'add-event-dialog',
      data: {
        day: date.getDate(),
        month: date.getMonth(),
        year: date.getFullYear(),
      },
    });
    ref.afterClosed().subscribe(() => {
      this.currentDate.next(this.currentDate.value);
      this.loading$.next(true);
    });
  }
}
