import { type } from 'os';
import { Injectable } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  Validators,
} from '@angular/forms';
import { AuthService } from '@common/auth/auth.service';
import { Toast } from '@common/core/toast/toast/toast.service';
import { ProfileService } from '@common/services/profile.service';
import { ProjectsService } from '@common/services/projects.service';
import { Profile } from 'src/app/models/profile';

@Injectable({
  providedIn: 'root',
})
export class ProfileEditingService {
  constructor(
    private fb: FormBuilder,
    private projects: ProfileService,
    private auth: AuthService,
    private toast: Toast
  ) {}

  public loading = false;
  public lastEpxandedPanel = 0;

  public setExpandedPanel(panel: number) {
    this.lastEpxandedPanel = panel;
  }

  public isExpanded(panel: number) {
    return this.lastEpxandedPanel === panel;
  }

  public formGroup = this.fb.group({
    id: [''],
    general: this.fb.group({
      name: [''], // disabled
      headnote: [''],
      avatarUrl: [''],
      avatarFile: [null],
      backdropUrl: [''],
      backdropFile: [null],
    }),
    tags: this.fb.array([]),
    about: [''],
    contactList: this.fb.array([]),
    contributors: this.fb.array([]),
    life: [''],
    addContactInfo: this.fb.group({
      name: [''],
      type: ['instagram'],
      data: [''],
    }),
    featuredContentCount: [
      4,
      { validators: [Validators.min(0), Validators.max(4)] },
    ],
    featuredContent: this.fb.array([]),
    addFeaturedContent: this.fb.group({
      linkOrId: [''],
    }),
    showPartners: [true],
    addContributor: [''],
    addParticipant: [''],
    extraSections: this.fb.array([]),
  });

  public getFinalData(): any {
    const { general, ...data } = this.formGroup.value;
    const finalData: any = {
      ...general,
      ...data,
    };
    finalData.description = finalData.about;
    return finalData;
  }

  public submit(projectData) {
    const finalData = this.getFinalData();
    this.loading = true;
    this.projects
      .updateProfile(projectData.type, projectData.id, {
        ...finalData,
        selectedProfileType: this.auth.selectedMember.profileType,
        selectedProfileId: this.auth.selectedMember.profileId,
      })
      .subscribe({
        next: (response) => {
          this.loading = false;
          this.toast.open('Profil Güncellendi');
        },
        error: (err) => {
          this.loading = false;
          this.toast.open('Bir hata oluştu');
        },
      });
  }

  public get extraSections() {
    return this.formGroup.get('extraSections') as FormArray;
  }

  public get tags() {
    return this.formGroup.controls['tags'] as FormArray;
  }

  public get applications() {
    return this.formGroup.controls['applications'] as FormArray;
  }

  public get tickets() {
    return this.formGroup.controls['tickets'] as FormArray;
  }

  public get addContributorControl() {
    return this.formGroup.get('addContributor') as FormControl;
  }

  public get addParticipantControl() {
    return this.formGroup.get('addParticipant') as FormControl;
  }

  public get contactInfo() {
    return this.formGroup.controls['contactList'] as FormArray;
  }

  public get participants() {
    return this.formGroup.controls['participants'] as FormArray;
  }

  public get contributors() {
    return this.formGroup.controls['contributors'] as FormArray;
  }

  public get timeline() {
    return this.formGroup.controls['timeline'] as FormArray<any>;
  }

  public get featuredContent() {
    return this.formGroup.controls['featuredContent'] as FormArray<any>;
  }

  public profile: Profile;
  public init(profileData): void {
    if (!profileData) return;
    this.profile = profileData;
    if (this.formGroup.get('id').value == profileData.id) return;

    this.formGroup.get('id').setValue(profileData.id);
    this.formGroup.get('general.name').setValue(profileData.name);
    if (profileData.type == 'User') {
      this.formGroup.get('general.name').disable();
    }
    this.formGroup.get('general.headnote').setValue(profileData.headnote);

    this.formGroup.get('about').setValue(profileData.about);
    this.formGroup.get('life').setValue(profileData.life);

    this.formGroup.get('general.avatarFile').patchValue(profileData.avatarFile);
    this.formGroup.get('general.avatarUrl').setValue(profileData.avatar);

    this.formGroup
      .get('general.backdropFile')
      .setValue(profileData.backdropFile);
    this.formGroup.get('general.backdropUrl').setValue(profileData.backdrop);

    for (let tag of (profileData.tags || [])) {
      this.tags.push(this.fb.control(tag));
    }

    for (let contact of profileData.contactList) {
      this.contactInfo.push(this.fb.control(contact));
    }

    for (let extra of profileData.extraSections) {
      this.extraSections.push(this.fb.control(extra));
    }

    for (let featuredContent of (profileData.featuredContent || [])) {
      this.addFeaturedContent(featuredContent);
    }
  }

  public addFeaturedContent(event: any) {
    if (event) {
      this.featuredContent.push(this.fb.control(event));
    }
  }
}
