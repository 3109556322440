import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { UploadService } from '@common/core/upload/upload.service';
import { ProfileEditingService } from '../../profile-editing.service';

@Component({
  selector: 'profile-editing-general',
  templateUrl: './profile-editing-general.component.html',
  styleUrls: ['./profile-editing-general.component.scss', '../panel.scss'],
})
export class ProfileEditingGeneralComponent {
  constructor(
    public profileEditing: ProfileEditingService,
    public uploadService: UploadService
  ) {}

  public get general() {
    return this.formGroup.get('general') as FormGroup;
  }

  public get formGroup() {
    return this.profileEditing.formGroup;
  }

  public uploadAvatar() {
    this.uploadService
      .openUploadDialog$('image', false, {
        aspectRatio: 1,
      })
      .subscribe((files) => {
        if (files) {
          this.formGroup.get('general.avatarFile').setValue(files[0]);
          this.formGroup
            .get('general.avatarUrl')
            .setValue(files[0].imagesData.variants[0]);
        }
      });
  }

  public uploadBackdrop() {
    this.uploadService
      .openUploadDialog$('image', false, {
        aspectRatio: 16 / 3,
      })
      .subscribe((files) => {
        if (files) {
          this.formGroup.get('general.backdropFile').setValue(files[0]);
          this.formGroup
            .get('general.backdropUrl')
            .setValue(files[0].imagesData.variants[0]);
        }
      });
  }

  public removeAvatar() {
    this.formGroup.get('general.backdropFile').setValue(null);
    this.formGroup.get('general.avatarUrl').setValue(null);
  }

  public removeBackdrop() {
    this.formGroup.get('general.backdropFile').setValue(null);
    this.formGroup.get('general.backdropUrl').setValue(null);
  }
}
