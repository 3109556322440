<main-content *ngIf="community">
  <div class="company-host container">
    <mat-card appearance="outlined" class="company-top">
      <div class="row">
        <div class="col images" [style]="getStyle(community)">
          <div class="logo">
            <img
              [src]="
                community.avatarFile
                  | imageFile : 'thumbnail' : community.avatar
              "
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col company-info">
          <div class="description">
            <span class="name">
              <h2>{{ community.name }}</h2>
              <mat-icon> verified </mat-icon>
            </span>
            <span class="text">
              {{ community.headnote }}
            </span>
            <span class="followers">
              {{ community.followerCount }} Takipçi
            </span>
          </div>
          <div class="buttons">
            <follow-button
              *ngIf="!isMe"
              [profile]="community"
              type="Community"
            ></follow-button>
            <button
              *ngIf="isMe"
              mat-stroked-button
              color="primary"
              [routerLink]="['/admin/profile']"
            >
              Profili Düzenle
            </button>
            <button
              [matMenuTriggerFor]="menu"
              mat-flat-button
              style="background-color: #c9cbfb; color: #4b53f2"
            >
              Daha Fazla
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="copyLink()">
                <mat-icon>share</mat-icon>
                Paylaş
              </button>
            </mat-menu>
          </div>
        </div>
      </div>
    </mat-card>

    <div class="row">
      <div class="col-lg-8">
        <mat-card appearance="outlined" class="about card">
          <div class="title">
            <h2>Hakkında</h2>
          </div>
          <p [innerHTML]="community.about"></p>

          <div class="row">
            <div class="col-sm-6" *ngFor="let contact of community.contactList">
              <p class="name">{{ contact.name }}</p>
              <p>{{ contact.data }}</p>
            </div>
          </div>
        </mat-card>

        <mat-card appearance="outlined" class="posts card">
          <div class="title">
            <h2>Gönderiler</h2>
            <div>
              <div>
                <button mat-button color="primary">Hepsini Gör</button>
              </div>
            </div>
          </div>
          <slider>
            <slider-item *ngFor="let post of posts">
              <featured-content [item]="post"></featured-content>
            </slider-item>
          </slider>
        </mat-card>

        <mat-card appearance="outlined" class="featured-content card">
          <div class="title">
            <h2>Öne Çıkan İçerikler</h2>
            <div>
              <div>
                <button mat-button color="primary">Hepsini Gör</button>
              </div>
            </div>
          </div>
          <slider>
            <slider-item *ngFor="let post of community.featuredContent">
              <featured-content [item]="post.content"></featured-content>
            </slider-item>
          </slider>
        </mat-card>

        <mat-card
          appearance="outlined"
          class="jobs card"
          *ngIf="jobPostings.length"
        >
          <div class="title">
            <h2>Yeni İş İlanları</h2>
            <div>
              <div>
                <button mat-button color="primary">Hepsini Gör</button>
              </div>
            </div>
          </div>
          <div class="jobs-area">
            <div class="row">
              <div class="col-6" *ngFor="let job of jobPostings">
                <div class="logo">
                  <img
                    [src]="job.creator.avatar"
                    style="height: auto !important"
                  />
                </div>
                <div class="text">
                  <h3>{{ job.title }}</h3>
                  <p>{{ job.locations.join(", ") }}</p>
                  <p>{{ job.viewStartDate | dateAgo }} Yayınlandı</p>
                </div>
              </div>
            </div>
          </div>
        </mat-card>

        <mat-card appearance="outlined" class="employees card">
          <div class="title">
            <h2>Üyeler</h2>
            <div>
              <button mat-button color="primary">Hepsini Gör</button>
            </div>
          </div>
          <div class="subtitle">
            <p></p>
          </div>
          <div class="employees-area">
            <div class="row">
              <div class="col-6" *ngFor="let member of members">
                <div class="logo">
                  <img [src]="member.member.avatar" />
                </div>
                <div class="text">
                  <h3>{{ member.member.display_name }}</h3>
                  <p>{{ member.member.headnote }}</p>
                  <p></p>
                  <follow-button
                    [profile]="member.member"
                    type="User"
                  ></follow-button>
                </div>
              </div>
            </div>
          </div>
        </mat-card>

        <mat-card appearance="outlined" class="contact card">
          <div class="title">
            <h2>İletişim</h2>
          </div>
          <div class="row">
            <div class="col-sm-6" *ngFor="let contact of community.contactList">
              <p class="name">{{ contact.name }}</p>
              <p>{{ contact.data }}</p>
            </div>
          </div>
        </mat-card>
      </div>
      <div class="col-lg-4">
        <summary></summary>
        <youmightknow></youmightknow>
      </div>
    </div>
  </div>
</main-content>
