<div class="container content">
  <div *ngIf="week$ | async as week">
    <div class="calendar">
      <div class="flexrow row-7-el">
        <div class="element" *ngFor="let day of week">
          <div class="header">
            <h3>
              {{ day.date | dateDayNumber }} • {{ day.date | dateDayName }}
            </h3>
          </div>
          <div class="add-note">
            <button
              mat-flat-button
              class="button-primary"
              *ngIf="day.note"
              (click)="viewNote(day.note)"
            >
              <mat-icon>note</mat-icon> <span>Not Oku</span>
            </button>
            <button
              mat-flat-button
              class="button-other"
              *ngIf="!day.note"
              (click)="addNote(day.date)"
            >
              <mat-icon>note_add</mat-icon> <span>Not Ekle</span>
            </button>
          </div>
          <div *ngFor="let item of day.items">
            <post-calendar-week-item
              *ngIf="isPost(item)"
              [post]="item"
            ></post-calendar-week-item>
            <calendar-event-week-item
              *ngIf="isEvent(item)"
              [item]="item"
            ></calendar-event-week-item>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
