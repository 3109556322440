import { Component, Input } from '@angular/core';
import { Project } from 'src/app/models/project';

@Component({
  selector: 'project-profile-about',
  templateUrl: './project-profile-about.component.html',
  styleUrls: ['./project-profile-about.component.scss', '../panel.scss']
})
export class ProjectProfileAboutComponent {
  @Input() public project: Project;

}
