import { Component, Input } from '@angular/core';
import { Event } from 'src/app/models/event';

@Component({
  selector: 'event-profile-applications',
  templateUrl: './event-profile-applications.component.html',
  styleUrls: ['./event-profile-applications.component.scss','../panel.scss']
})
export class EventProfileApplicationsComponent {
  @Input() public event: Event;
}
