<mat-card class="profile-card">
  <div class="card-content">
    <h1>Konuşmacılar ve Düzenleyenler</h1>
    <div class="participants row">
      <div class="col-sm-4" *ngFor="let participant of participants">
        <div class="participant">
          <div class="image-container">
            <img [src]="participant.user.avatar" alt="Profile image" />
          </div>
          <div class="info-container">
            <h4 class="name">
              {{ participant.user.display_name || participant.user.name }}
            </h4>
            <p class="role">{{ participant.role.display_name }}</p>
          </div>
        </div>
        <div>
          <follow-button
            [profile]="participant.user"
            type="User"
            ></follow-button>
        </div>
      </div>
    </div>
  </div>
</mat-card>
