<div class="panel" [formGroup]="profileEditing.formGroup">
  <div class="content">
    <h2>Deneyim ve Diğer Bilgiler</h2>

    <div>
      <div class="d-flex justify-content-end">
        <button
          mat-flat-button
          class="button-primary"
          (click)="addContactInfo()"
        >
          Yeni Ekle
        </button>
      </div>
    </div>
    <div class="contacts">
      <div class="order-list" cdkDropList>
        <div
          class="order-item"
          style="background-color: var(--surface-2); border: 1px solid var(--stroke); padding: 10px; margin-top: 10px;"
          cdkDrag
          *ngFor="let control of contactInfo.controls; let i = index"
        >
          

          <div class="order-info">
            <h4>{{ control.value.organization }}</h4>
            <p>{{ control.value.name }}</p>
          </div>

          <div class="order-edit">
            <button type="button" mat-icon-button (click)="editContactInfo(i)">
              <mat-icon>edit</mat-icon>
            </button>
          </div>

          <div class="order-remove">
            <button
              type="button"
              mat-icon-button
              (click)="removeContactInfo(i)"
            >
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
