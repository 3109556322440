<p class="post-content-p">
  <ng-container *ngFor="let word of parsedContent">
    <span *ngIf="!isHashtag(word)" [innerHtml]="word + ' '"></span>
    <span class="tag" *ngIf="isHashtag(word)">
      <a [routerLink]="['/search']" [queryParams]="{ query: word.replace('#','')}">
        {{ word }}
      </a>
    </span>
  </ng-container>
</p>
