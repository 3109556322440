<form [formGroup]="formGroup" (submit)="submit()">
  <div
    class="content d-flex flex-column align-items-start justify-content-center"
  >
    <div class="title">
      <b>Yeni İçerik Ekleyin</b>
    </div>
    <div class="link">
      <div>
        <mat-form-field appearance="outline" class="link-input">
          <input
            matInput
            type="text"
            placeholder="Link ekleyin"
            formControlName="url"
          />
        </mat-form-field>
      </div>
      <div>
        <button mat-flat-button color="accent" type="submit">Ekle</button>
      </div>
    </div>
    <hr />
    <p>Veya</p>
    <div class="text">
      <p>
        Gönderi Yayın Merkezinde takvimde bir gönderide üç noktaya tıklayın ve
        “Öne Çıkarılan İçeriklere Ekle” tuşuna basın.
      </p>
    </div>
  </div>
</form>
