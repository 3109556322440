<h1 mat-dialog-title>Bölüm Ekle</h1>
<div mat-dialog-content>
  <form [formGroup]="formGroup">
    <div class="content">
      <div class="grid">
        <div class="name">
          <mat-form-field appearance="outline">
            <mat-label>Başlık</mat-label>
            <input formControlName="name" matInput placeholder="Başlık" />
          </mat-form-field>
        </div>
        <div class="startTime">
            <mat-form-field appearance="outline">
                <mat-label>Tarih</mat-label>
                <input type="text" formControlName="date" matInput [matDatepicker]="picker">
                <mat-hint>MM/DD/YYYY</mat-hint>
                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
        </div>        
      </div>
    </div>
    <div class="description">
      <mat-form-field appearance="outline">
        <textarea
          matInput
          formControlName="description"
          placeholder="Bölümün kısa özetini girin."
          rows="5"
        ></textarea>
      </mat-form-field>
    </div>

    <div class="add-image">
        <button mat-flat-button color="accent" (click)="addImage()">Resim Ekle</button>
    </div>
    
    <div class="images row">
        <div class="image col-sm-4" *ngFor="let image of images.controls">
            <img [src]="image.value.imagesData.variants[0]" alt="Timeline image">
        </div>
    </div>
    
  </form>
</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="">İptal</button>
  <button mat-button [mat-dialog-close]="result" cdkFocusInitial>Ekle</button>
</div>
