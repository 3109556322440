import { Component, Input } from '@angular/core';
import { Post } from 'src/app/models/post';

@Component({
  selector: 'post-calendar-list-item',
  templateUrl: './post-calendar-list-item.component.html',
  styleUrls: ['./post-calendar-list-item.component.scss']
})
export class PostCalendarListItemComponent {
  @Input() post: Post;

  public getStatusColor() {
    const dateDiffrence = new Date(this.post.visibleFrom).getTime() - new Date().getTime();
    if (dateDiffrence < 0) {
      return 'success';
    } else if (dateDiffrence < 1000 * 60 * 60 * 24 * 7) {
      return 'info';
    } else {
      return 'info';
    }
  }

  public getStatusText() {
    const dateDiffrence = new Date(this.post.visibleFrom).getTime() - new Date().getTime();
    if (dateDiffrence < 0) {
      return 'Yayınlandı';
    } else if (dateDiffrence < 1000 * 60 * 60 * 24 * 7) {
      return 'Planlandı';
    } else {
      return 'Planlandı';
    }
  }

  public getStatusIcon() {
    const dateDiffrence = new Date(this.post.visibleFrom).getTime() - new Date().getTime();
    if (dateDiffrence < 0) {
      return 'check';
    } else if (dateDiffrence < 1000 * 60 * 60 * 24 * 7) {
      return 'schedule';
    } else {
      return 'schedule';
    }
  }
}
