<h1 mat-dialog-title>Not Ekle</h1>
<div mat-dialog-content>
  <form [formGroup]="formGroup" (submit)="submit()">
    <p>Notunuza başlık girin:</p>
    <mat-form-field appearance="outline">
        <mat-label>Başlık</mat-label>
        <input matInput placeholder="" formControlName="title" required />
    </mat-form-field>

    <p>Detayları girin:</p>
    <text-editor [content]="content || ''" #editor (onChange)="change()" ></text-editor>
  </form>
</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="">İptal</button>
  <button mat-button (click)="submit()" [disabled]="loading$ | async" cdkFocusInitial>Kaydet</button>
</div>
