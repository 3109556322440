import { Injectable } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { UploadService } from '@common/core/upload/upload.service';
import { PollDialogComponent } from '@common/ui/poll-dialog/poll-dialog.component';
import { Subscription } from 'rxjs';
import { CreatePost, Visibility, NotifyOptions } from '../models/CreatePost';
import { PostService } from '../services/post.service';
import { SearchEventComponent } from '@common/ui/search-event/search-event.component';

@Injectable({
  providedIn: 'root',
})
export class ContentEditingService {
  visibilityOptions = Visibility;
  notifyOptions = Object.values(NotifyOptions);
  sanitizedHtml: SafeHtml = '';
  uploadSubscription: Subscription;

  constructor(
    private fb: FormBuilder,
    private sanitizer: DomSanitizer,
    private uploadService: UploadService,
    private postService: PostService,
    private dialog: MatDialog,
    private router: Router
  ) {}

  createPostForm = this.fb.group({
    text: ['', Validators.required],
    images: this.fb.array([]),
    videos: this.fb.array([]),
    visibility: ['PUBLIC', Validators.required],
    notify: this.fb.array([]),
    event: [],
    dateRange: this.fb.group({
      visibleFrom: [],
      visibleUntil: [],
    }),
    poll: this.fb.group({
      question: [],
      allowMultipleAnswers: [false],
      options: this.fb.array([]),
    }),
    postLanguage: ['Turkish', Validators.required],
    postLocation: ['Türkiye', Validators.required],
    contributors: this.fb.array([]),
    notifyValues: this.fb.array([]),
  });

  public contributorForm = this.fb.group({
    profile: [''],
  });

  public get contributors() {
    return this.createPostForm.get('contributors') as FormArray;
  }

  public get profile() {
    return this.contributorForm.get('profile') as FormControl;
  }

  public addContributor() {
    this.contributors.push(this.fb.control(this.contributorForm.value.profile));
    this.contributorForm.reset();
  }

  public get images() {
    return this.createPostForm.get('images') as FormArray;
  }

  public get videos() {
    return this.createPostForm.get('videos') as FormArray;
  }

  onUpload(fileType: string) {
    this.uploadSubscription = this.uploadService
      .openUploadDialog$(fileType, true, {
        allowCrop: false,
      })
      .subscribe((files: any) => {
        if (files && files.length > 0) {
          for (const file of files) {
            if (file.imagesData?.variants) {
              this.images.push(this.fb.control(file));
            } else if (file.streamData) {
              this.videos.push(this.fb.control(file));
            }
          }
        }
      });
  }

  public addPoll() {
    const ref = this.dialog.open(PollDialogComponent, {
      data: {},
    });
    ref.afterClosed().subscribe((result) => {
      if (result) {
        this.createPostForm.get('poll.question').setValue(result.question);
        this.createPostForm.get('poll.allowMultipleAnswers').setValue(result.allowMultipleAnswers);
        for (const option of result.options) {
          (this.createPostForm.get('poll.options') as FormArray).push(
            this.fb.control(option)
          );
        }
        if (!window.location.pathname.includes('admin')) {
          this.router.navigate(['/admin', 'content-editing']);
        }
      }
    });
  }

  public addEvent() {
    if (this.createPostForm.get('event').value) {
      this.createPostForm.get('event').reset();
      return;
    }
    const ref = this.dialog.open(SearchEventComponent, {
      data: {},
    });

    ref.afterClosed().subscribe((result) => {
      if (result) {
        this.createPostForm.get('event').setValue(result);
        console.log(this.createPostForm.get('event').value);
      } else {
        this.createPostForm.get('event').reset();
      }
    });
  }
}
