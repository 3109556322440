import { Component, Input } from '@angular/core';

@Component({
  selector: 'calendar-list',
  templateUrl: './calendar-list.component.html',
  styleUrls: ['./calendar-list.component.scss'],
})
export class CalendarListComponent {
  @Input() public list: any[] = [];

  public isPost = (item: any) => item.type === 'post' || item?.text;
  public isEvent = (item: any) => item.type === 'event' || item?.startDate;

}
