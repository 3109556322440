export const eventColors = [
    {
      label: 'Mavi',
      value: 'blue',
      colors: ['#6F75F5', '#5D64F3', '#4B53F2', '#444BDA', '#3C42C2'],
    },
    {
      label: 'Mor',
      value: 'purple',
      colors: ['#B36BFF', '#A45AFF', '#9549FF', '#8C42E3', '#833AC7', '#7A32AB'],
    },
    {
      label: 'Pembe',
      value: 'pink',
      colors: ['#FF6B6B', '#FF5A5A', '#FF4949', '#E34242', '#C83A3A'],
    },
    {
      label: 'Kırmızı',
      value: 'red',
      colors: ['#FF6B6B', '#FF5A5A', '#FF4949', '#E34242', '#C83A3A'],
    },
    {
      label: 'Turuncu',
      value: 'orange',
      colors: ['#FFA36B', '#FF965A', '#FF8949', '#E37D42', '#C8713A'],
    },
    {
      label: 'Sarı',
      value: 'yellow',
      colors: ['#FFD66B', '#FFCC5A', '#FFC249', '#E3B942', '#C8A73A'],
    },
    {
      label: 'Gri',
      value: 'gray',
      colors: ['#BDBDBD', '#A8A8A8', '#939393', '#7D7D7D', '#686868'],
    },
  ];