<div class="panel" [formGroup]="profileEditing.formGroup">
  <h2>İletişim Bilgileri</h2>
  <div class="content">
    <div class="subtitle">
      <p>
        Kullanıcıların şirketiniz ile iletişime geçebilmesi ve markanızın farklı
        platformlardaki hesaplarını ekleyin.
      </p>
    </div>
    <div
      class="contact d-flex justify-content-between align-items-end st"
      formGroupName="addContactInfo"
    >
      <div class="option">
        <p>İletişim Çeşidi</p>
        <mat-select formControlName="type">
          <mat-option value="twitter">Twitter</mat-option>
          <mat-option value="instagram">Instagram</mat-option>
          <mat-option value="phone">Telefon Numarası</mat-option>
          <mat-option value="email">E-posta Adresi</mat-option>
          <mat-option value="website">Web Sitesi</mat-option>
        </mat-select>
      </div>
      <div class="option">
        <p>Başlık</p>
        <input
          type="text"
          formControlName="name"
          placeholder="Turkcell Twitter Sayfası"
        />
      </div>
      <div class="option">
        <p>URL</p>
        <input formControlName="data" type="text" placeholder="@Turkcell" />
      </div>
      <button
        type="button"
        mat-flat-button
        color="primary"
        (click)="addContactInfo()"
      >
        Ekle
      </button>
    </div>
    <div class="contacts" style="margin-top: 20px;">
      <hr>
      <div class="order-list" cdkDropList>
        <div
          class="order-item"
          style="background-color: var(--surface-2); border: 1px solid var(--stroke); padding: 10px;"
          cdkDrag
          *ngFor="let control of contactInfo.controls; let i = index"
        >
         

          <div class="order-info">
            <h4>{{ control.value.name }}</h4>
            <p>{{ control.value.data }}</p>
          </div>

          <div class="order-remove">
            <button
              type="button"
              mat-icon-button
              (click)="removeContactInfo(i)"
            >
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
