import { Injectable } from '@angular/core';
import { AppHttpClient } from '@common/core/http/http.service';
import { StandartResponse } from '@common/core/http/standart-response';
import { CustomPage } from 'src/app/models/custom-page';
import { Pagination } from 'src/app/models/pagination';

const BASE = 'blog';

@Injectable({
  providedIn: 'root'
})
export class BlogService {
  

  constructor(
    private http:AppHttpClient
  ) { }

  public getHome() {
    return this.http.get<StandartResponse<{
      featured:CustomPage[],
      latest:CustomPage[]
      suggestions:CustomPage[]
    }>>(`${BASE}/home`);
  }

  public indexPosts() {
    return this.http.get<StandartResponse<Pagination<CustomPage>>>(BASE);
  }

  public getBlogPost(id:string) {
    return this.http.get<StandartResponse<{
      blog:CustomPage,
      blogsFromSameAuthor:CustomPage[]
    }>>(`${BASE}/${id}`);
  }

  public createPost(data:any) {
    return this.http.post<StandartResponse<CustomPage>>(BASE,data);
  }

  public updatePost(postId:string,data:any) {
    return this.http.put<StandartResponse<CustomPage>>(`${BASE}/${postId}`,data);
  }

  public deletePost(id: string) {
    return this.http.delete<StandartResponse<CustomPage>>(`${BASE}/${id}`);
  }

  public getUserPosts(user:string) {
    return this.http.get<StandartResponse<CustomPage[]>>(`${BASE}/user/${user}`)
  }
}
