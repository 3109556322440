<main-content *ngIf="company">
  <ng-container *ngIf="!loading">
    <div class="company-host container">
      <mat-card appearance="outlined" class="company-top">
        <div class="row">
          <div class="col images" [style]="getStyle(company)">
            <div class="logo">
              <media-image
                appereance="rounded"
                width="100"
                height="100"
                [src]="company.avatar"
                alt=""
              ></media-image>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col company-info">
            <div class="description">
              <span class="name">
                <h2>{{ company.name }}</h2>
                <mat-icon> verified </mat-icon>
              </span>

              <p style="padding-top: 5px; padding-bottom: 7px">
                {{ company.headnote
                }}<ng-container *ngIf="company.parent">
                  •
                  <a [routerLink]="company.parent | appUrl">{{
                    company.parent.display_name
                  }}</a>
                  Kurumu Altında
                </ng-container>
                <span class="followers">
                  {{
                    company.followerCount ? company.followerCount : 0
                  }}
                  Takipçi
                </span>
              </p>
            </div>
            <div class="buttons">
              <button
                *ngIf="isMe"
                mat-stroked-button
                color="primary"
                [routerLink]="['/admin/profile']"
              >
                Profili Düzenle
              </button>
              <follow-button
                *ngIf="!isMe"
                [profile]="company"
                type="Company"
              ></follow-button>
              <button
                [matMenuTriggerFor]="menu"
                mat-flat-button
                style="background-color: #c9cbfb; color: #4b53f2"
              >
                Daha Fazla
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="copyLink()">
                  <mat-icon>share</mat-icon>
                  Paylaş
                </button>
              </mat-menu>
            </div>
          </div>
        </div>
      </mat-card>
      <div class="row">
        <div class="col-lg-8">
          <mat-tab-group #tabGroup class="tab-group">
            <mat-tab label="Ana Sayfa">
              <mat-card appearance="outlined" class="about card">
                <div class="title">
                  <h2>Hakkında</h2>
                </div>
                <p [innerHTML]="company.about"></p>

                <div class="row">
                  <div
                    class="col-sm-6"
                    *ngFor="let contact of company.contactList"
                  >
                    <p class="name">{{ contact.name }}</p>
                    <p>{{ contact.data }}</p>
                  </div>
                </div>
              </mat-card>

              <mat-card
                *ngIf="children?.length"
                appearance="outlined"
                class="posts card"
              >
                <div class="title">
                  <h2>Bağlı Şirketler</h2>
                  <div></div>
                </div>
                <div class="row">
                  <div
                    class="col-6 hover-effect"
                    *ngFor="let item of children"
                    [routerLink]="item | appUrl"
                  >
                    <div class="logo">
                      <media-image
                        appereance="circle"
                        width="48"
                        height="48"
                        [src]="item.avatar"
                      ></media-image>
                    </div>
                    <div class="text">
                      <h3 style="margin: 2px">{{ item.display_name }}</h3>
                      <p style="margin: 2px; color: var(--sub-text)">
                        {{
                          item.followerCount ? item.followerCount : 0
                        }}
                        Takipçi
                      </p>
                    </div>
                  </div>
                </div>
              </mat-card>

              <mat-card appearance="outlined" class="posts card">
                <div class="title">
                  <h2>Gönderiler</h2>
                  <div>
                    <div>
                      <button mat-button color="primary">Hepsini Gör</button>
                    </div>
                  </div>
                </div>
                <slider>
                  <slider-item *ngFor="let post of posts">
                    <featured-content
                      [item]="post"
                      [showButton]="false"
                    ></featured-content>
                  </slider-item>
                </slider>
              </mat-card>

              <mat-card appearance="outlined" class="featured-content card">
                <div class="title">
                  <h2>Öne Çıkan İçerikler</h2>
                  <div>
                    <div>
                      <button mat-button color="primary">Hepsini Gör</button>
                    </div>
                  </div>
                </div>
                <slider>
                  <slider-item *ngFor="let post of company.featuredContent">
                    <featured-content
                      [item]="post.content"
                      [showButton]="false"
                    ></featured-content>
                  </slider-item>
                </slider>
              </mat-card>

              <mat-card appearance="outlined" class="company-life card">
                <div class="row">
                  <div class="col-sm-6">
                    <img [src]="company.backdropFile | imageFile" alt="" />
                  </div>
                  <div class="col-sm-6">
                    <h2>{{ company.name }} Kurumunda İş Yaşamı</h2>
                    <button
                      mat-flat-button
                      style="background-color: #c9cbfb; color: #4b53f2"
                      (click)="tabGroup.selectedIndex = 2"
                    >
                      İş Yaşamı
                    </button>
                  </div>
                </div>
              </mat-card>

              <mat-card
                appearance="outlined"
                class="jobs card"
                *ngIf="jobPostings.length"
              >
                <div class="title">
                  <h2>Yeni İş İlanları</h2>
                  <div>
                    <div>
                      <button mat-button color="primary">Hepsini Gör</button>
                    </div>
                  </div>
                </div>
                <div class="jobs-area">
                  <div class="row">
                    <div
                      class="col-6 hover-effect"
                      *ngFor="let job of jobPostings"
                      [routerLink]="['/job-posting', job.id]"
                    >
                      <div class="logo">
                        <media-image
                          appereance="circle"
                          width="48"
                          height="48"
                          [src]="job.creator.avatar"
                        ></media-image>
                      </div>
                      <div class="text">
                        <h3>{{ job.title }}</h3>
                        <p>
                          {{ job.locations.join(", ") }}
                          <ng-container *ngIf="job.creatorId != company.id">
                            •
                            {{ job.creator.display_name }}
                            Şirketinde</ng-container
                          >
                        </p>
                        <p>{{ job.viewStartDate | dateAgo }} Yayınlandı</p>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-card>

              <mat-card appearance="outlined" class="employees card">
                <div class="title">
                  <h2>Çalışanlar</h2>
                </div>
                <div class="subtitle">
                  <p></p>
                </div>
                <div class="employees-area">
                  <div class="row justify-content-between">
                    <div
                      class="col-6 hover-effect"
                      *ngFor="let member of members"
                      [routerLink]="['/user-profile', member.member.name]"
                    >
                      <div class="logo">
                        <media-image
                          appereance="circle"
                          width="48"
                          height="48"
                          [src]="member.member.avatar"
                        ></media-image>
                      </div>
                      <div class="text">
                        <h3>{{ member.member.display_name }}</h3>
                        <p>{{ member.member.headnote }}</p>
                        <p></p>
                        <follow-button
                          [profile]="member.member"
                          type="User"
                        ></follow-button>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-card>

              <mat-card appearance="outlined" class="contact card">
                <div class="title">
                  <h2>İletişim</h2>
                </div>
                <div class="row">
                  <div
                    class="col-sm-6"
                    *ngFor="let contact of company.contactList"
                  >
                    <p class="name">{{ contact.name }}</p>
                    <p>{{ contact.data }}</p>
                  </div>
                </div>
              </mat-card>
            </mat-tab>
            <mat-tab label="İş İlanları">
              <company-job-postings
                [company]="company"
                [jobPostings]="jobPostings"
                [members]="members"
                [isMe]="isMe"
              ></company-job-postings>
            </mat-tab>
            <mat-tab label="İş Yaşamı">
              <mat-card
                style="border-radius: 7px; padding: 16px; margin-top: 16px"
              >
                <h2>{{ company.name }} Şirketinde İş Yaşamı</h2>
                <p class="life" [innerHTML]="company.life"></p>
              </mat-card>
            </mat-tab>
          </mat-tab-group>
        </div>
        <div class="col-lg-4">
          <div class="mt-2 mb-5">
            <summary></summary>
          </div>
          <div class="mb-5">
            <youmightknow></youmightknow>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="loading">
    <div class="container">
      <ngx-skeleton-loader
        [theme]="{ height: '200px', width: '100%', marginTop: '20px' }"
      ></ngx-skeleton-loader>
      <div class="row">
        <div class="col-lg-8">
          <ngx-skeleton-loader
            [count]="5"
            [theme]="{ height: '200px', width: '100%', marginTop: '20px' }"
          ></ngx-skeleton-loader>
        </div>
        <div class="col-lg-4">
          <ngx-skeleton-loader
            [count]="5"
            [theme]="{ height: '200px', width: '100%', marginTop: '20px' }"
          ></ngx-skeleton-loader>
        </div>
      </div>
    </div>
  </ng-container>
</main-content>
