import { Component, Input } from '@angular/core';
import { Event } from 'src/app/models/event';

@Component({
  selector: 'event-profile-contact',
  templateUrl: './event-profile-contact.component.html',
  styleUrls: ['./event-profile-contact.component.scss', '../panel.scss'],
})
export class EventProfileContactComponent {
  @Input() public event: Event;
}
