import { Component } from '@angular/core';
import { FormArray, FormBuilder } from '@angular/forms';
import { ProfileEditingService } from '../../profile-editing.service';

@Component({
  selector: 'profile-editing-featured-content',
  templateUrl: './profile-editing-featured-content.component.html',
  styleUrls: [
    './profile-editing-featured-content.component.scss',
    '../panel.scss',
  ],
})
export class ProfileEditingFeaturedContentComponent {
  constructor(
    public profileEditing: ProfileEditingService,
    public fb: FormBuilder
  ) {}

  public get formGroup() {
    return this.profileEditing.formGroup;
  }

  public get featuredContent() {
    return this.formGroup.controls['featuredContent'] as FormArray<any>;
  }

  public removeFeaturedContent(index) {
    this.featuredContent.removeAt(index);
  }
}
