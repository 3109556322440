import {
  ApplicationRef,
  Inject,
  Injectable,
  PLATFORM_ID,
  inject,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '@common/auth/auth.service';
import { Socket, SocketIoConfig } from 'ngx-socket-io';
import { BehaviorSubject, Observable, first, map } from 'rxjs';
import { NewChatComponent } from './components/new-chat/new-chat.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Profile } from 'src/app/models/profile';
import { MessageRoom } from 'src/app/models/messages/message-room';
import { StandartResponse } from '@common/core/http/standart-response';
import { Pagination } from 'src/app/models/pagination';
import { MessageMember } from 'src/app/models/messages/message-member';
import { Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { environment } from 'src/environments/environment';
import { AppHttpClient } from '@common/core/http/http.service';

@Injectable({
  providedIn: 'root',
})
export class MessagingService {
  public currentRoom$ = new BehaviorSubject<any>(null);
  public joinedRoom = null;

  public currentMenu = 'roomDetails';

  private config: SocketIoConfig = {
    url: environment.api,
    options: {
      transports: ['websocket'],
      autoConnect: false,
      extraHeaders: {
        Authorization: `Bearer ${this.http.getToken()}`,
      },
    },
  };

  private socket = new Socket(this.config);

  constructor(
    private auth: AuthService,
    private dialog: MatDialog,
    private bottomSheet: MatBottomSheet,
    private router: Router,
    private http: AppHttpClient,
    @Inject(PLATFORM_ID) private platformId: object
  ) {
    inject(ApplicationRef)
      .isStable.pipe(first((isStable) => isStable))
      .subscribe(() => {
        this.socket.connect((err) => {
          if (err) {
            console.log('Socket connection error', err);
          } else {
            this.socket.emit('login', {
              token: this.http.getToken(),
            });
          }
        });
      });

    this.currentRoom$.subscribe((room) => {
      if (room && this.joinedRoom != room.id) {
        this.socket.emit('joinRoom', room.id);
        this.joinedRoom = room.id;
      }
      if (room) {
        try {
          localStorage.setItem('lastRoom', JSON.stringify(room));
        } catch (error) {
          console.log(error);
        }
      }
    });
    if (!this.currentRoom$.value) {
      try {
        const lastRoomJson = localStorage.getItem('lastRoom');
        if (lastRoomJson) {
          const lastRoom = JSON.parse(lastRoomJson);
          this.currentRoom$.next(lastRoom);
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  public rooms$ = this.emitWithAck<any[]>('getRooms', {
    memberType: this.auth.selectedProfile.type,
    memberId: this.auth.selectedProfile.id,
  });

  public updateRoom(room: any) {
    return this.emitWithAck<MessageRoom>('updateRoom', { room });
  }

  public toggleIsAdmin(memberId: string, roomId: string) {
    return this.emitWithAck<MessageRoom>('toggleIsAdmin', {
      memberId,
      roomId,
    });
  }

  public removeMember(memberId: string, roomId: string) {
    return this.emitWithAck<MessageRoom>('removeMember', {
      memberId,
      roomId,
    });
  }

  public onNewMessage() {
    return new Observable<any>((observer) => {
      this.socket.on('newMessage', (message: any) => {
        console.log('newMessage', message);
        observer.next(message);
      });
    });
  }

  public searchDmRoomByReceiver(receiver: Profile) {
    return this.emitWithAck<any>('searchDmRoomByReceiver', {
      receiverId: receiver.id,
    });
  }

  public openRoomByProfile(profile: Profile, route = true) {
    return new Observable<boolean>((observer) => {
      this.searchDmRoomByReceiver(profile).subscribe((room) => {
        if (room) {
          this.currentRoom$.next(room);
          if (route) {
            this.router.navigate(['/messages']);
          }
          observer.next(true);
        } else {
          const newRoom: any = {};
          const image = profile.avatar;
          newRoom.avatar = image;
          newRoom.image = image;
          newRoom.name = profile.display_name || profile.name;
          newRoom.id = null;
          newRoom.receiver = profile.id;
          newRoom.members = [
            {
              memberId: profile.id,
              memberType: profile.type,
            },
            {
              memberId: this.auth.selectedProfile.id,
              memberType: this.auth.selectedProfile.type,
            },
          ];
          newRoom.admins = [];
          this.currentRoom$.next(newRoom);
          if (route) {
            this.router.navigate(['/messages']);
          }
          observer.next(true);
        }
      });
    });
  }

  public onNewRoom() {
    return new Observable<any>((observer) => {
      this.socket.on('newRoom', (room: any) => {
        observer.next(room);
      });
    });
  }

  public getMessagesHasLinks(roomId: string) {
    return this.emitWithAck<any[]>('getMessagesHasLinks', { roomId });
  }

  public getMessagesHasFiles(roomId: string) {
    return this.emitWithAck<any[]>('getMessagesHasFiles', { roomId });
  }

  public getMembers(roomId: string) {
    return this.emitWithAck<MessageMember[]>('getMembers', { roomId });
  }

  public getMessages(roomId: string) {
    return this.emitWithAck<any[]>('getMessages', { roomId });
  }

  public sendMessage(
    roomId: string = null,
    receiverId: string = null,
    message: any
  ) {
    const senderId = this.auth.user.id;
    const senderType = 'User';
    const senderAvatar = this.currentRoom$.value.image;
    this.emitWithAck('sendMessage', {
      roomId,
      receiverId,
      text: message.message,
      senderId,
      senderType,
      senderAvatar,
      files: message.files,
    }).subscribe((response: any) => {
      if (!roomId) {
        console.log('new room', response);
        this.currentRoom$.next(response.room);
      }
      console.log('message sent', response);
    });
  }

  public emitWithAck<T>(eventName: string, data: any = undefined) {
    return new Observable<T>((observer) => {
      this.socket.emit(eventName, data, (response: any) => {
        console.log('emitWithAck Response', response);
        observer.next(response.data);
        observer.complete();
      });
    });
  }

  public openNewChatDialog() {
    const ref = this.bottomSheet.open(NewChatComponent);
    ref
      .afterDismissed()
      .subscribe((room: { type: string; name: string; users: Profile[] }) => {
        if (!room) return;

        const newRoom: any = {};
        console.log('room', room);
        if (room.type == 'dm' && room.users.length > 0) {
          const image = room.users[0].avatar;
          newRoom.avatar = image;
          newRoom.image = image;
          newRoom.name = room.users[0].display_name || room.users[0].name;
          newRoom.id = null;
          newRoom.receiver = room.users[0].id;
          newRoom.members = [
            {
              memberId: room.users[0].id,
              memberType: room.users[0].type,
            },
            {
              memberId: this.auth.selectedProfile.id,
              memberType: this.auth.selectedProfile.type,
            },
          ];
          newRoom.admins = [];
          this.currentRoom$.next(newRoom);
        } else {
          newRoom.name = room.name;
          newRoom.type = room.type;
          newRoom.members = room.users.map((u) => {
            return {
              memberId: u.id,
              memberType: u.type,
            };
          });
          newRoom.creatorType = this.auth.selectedMember.profileType;
          newRoom.creatorId = this.auth.selectedMember.profileId;

          this.emitWithAck('createGroupRoom', newRoom).subscribe(
            (response: MessageRoom) => {
              this.currentRoom$.next(response);
            }
          );
        }
      });
  }
}
