<mat-card class="profile-card">
    <div class="card-content">
        <h1>İletişim</h1>
        <div class="contents row">
            <div class="content col-sm-4" *ngFor="let content of event.contactList">
                <div class="contact">
                   <p class="name">{{content.name}}</p>
                   <p class="data">{{content.data}}</p>
                </div>
            </div>
        </div>
    </div>
</mat-card>