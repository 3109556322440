<div>
  <mat-card class="day-card" *ngFor="let day of list">
    <div class="title">{{ day.date | dateDayMonth }}</div>
    <div class="event-list">
      <div class="row" *ngIf="day.items.length > 0; else empty">
        <div class="col" *ngFor="let item of day.items">
          <post-calendar-list-item *ngIf="isPost(item)" [post]="item"></post-calendar-list-item>
          <calendar-event-list-item *ngIf="isEvent(item)" [item]="item"></calendar-event-list-item>
        </div>
      </div>
      <ng-template #empty>
        <div class="empty">
          <p>Bugün için bir gönderi bulunmuyor.</p>
        </div>
      </ng-template>
    </div>
  </mat-card>
</div>
