import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@common/auth/auth.service';
import { imageFile } from '@common/core/pipes/image-file.pipe';
import { Toast } from '@common/core/toast/toast/toast.service';
import { CommunitiesService } from '@common/services/communities.service';
import { CompaniesService } from '@common/services/companies.service';
import { MembersService } from '@common/services/members.service';
import { ProfileService } from '@common/services/profile.service';
import { switchMap, map } from 'rxjs';
import { Community } from 'src/app/models/community';
import { Company } from 'src/app/models/company';
import { JobPosting } from 'src/app/models/job-posting';
import { Post } from 'src/app/models/post';
import { Members } from 'src/app/models/relations/members';

@Component({
  selector: 'commuity-profile',
  templateUrl: './commuity-profile.component.html',
  styleUrls: ['./commuity-profile.component.scss'],
})
export class CommuityProfileComponent {
  constructor(
    public profileService: ProfileService,
    public currentUser: AuthService,
    public activatedRoute: ActivatedRoute,
    public membersService: MembersService,
    public companies: CommunitiesService,
    public toast: Toast
  ) {}

  public isMe = false;

  public getStyle(company: Community) {
    return {
      'background-image': `url(${imageFile(
        company.backdropFile,
        'thumbnail',
        company.backdrop
      )})`,
    };
  }

  public companyData$ = this.activatedRoute.params
    .pipe(switchMap((params) => this.companies.getCommunity(params['id'])))
    .pipe(map((res) => res.data));

  public members: Members[] = [];
  public jobPostings: JobPosting[] = [];
  public community: Community;
  public posts: Post[] = [];

  ngOnInit(): void {
    this.companyData$.subscribe((data) => {
      this.members = data.members;
      this.jobPostings = data.jobPostings;
      this.community = data.community;
      this.posts = data.posts;
      this.isMe =
        this.currentUser.selectedMember.profileId === this.community.id;
    });
  }

  public copyLink() {
    navigator.clipboard.writeText(window.location.href);
    this.toast.open('Bağlantı kopyalandı');
  }

  public post = {
    type: 'Gönderi',
    image:
      'https://blog.playstation.com/tachyon/2023/03/32cc019fdeb424906ac3ebf8e697ad190df79b8e.jpg?resize=1088%2C612&crop_strategy=smart',
    content:
      'LeBron James Limited Edition PS5 Konsol Kapaklarına ve DualSense kablosuz kontrol cihazına göz atın. İşte spor ikonu ve PlayStation Playmaker ile özel bir işbirliğine ilk bakış.',
  };
  public post2 = {
    type: 'Gönderi',
    image: 'https://i.ytimg.com/vi/E3Bu9aBzKk4/maxresdefault.jpg',
    content:
      "Sony Interactive Entertainment, Kadın Tarihi Ayı'nı kutlayan küresel topluluğa katılmaktan heyecan duyuyor. Mart ayı boyunca, kadınların hem oyun oynamalarına hem de topluma katkılarını anacağız. PlayStation Store'da küratörlüğünü yaptığımız Amazing Heroines koleksiyonumuza göz atmayı unutmayın. PlayStation'da bulunan en heyecan verici kadın karakterlerden, anlatılardan ve hikayelerden bazılarını öne çıkarıyor. Bu koleksiyonların oluşturulması önemlidir, çünkü bir kahramanın topluluğumuz için nasıl görünebileceğine dair imajı ve algıyı etkilemeyi umuyoruz. Birlikte oyunun sınırlarını zorlayalım!",
  };
  public post3 = {
    type: 'Gönderi',
    image:
      'https://static-prod.adweek.com/wp-content/uploads/2021/04/Eric-Lempel_ITB2-652x367.jpg',
    content:
      "Oyunun gücünü kanıtlayan tutkulu oyuncular, içerik oluşturucular, sporcular, sanatçılar ve şovmenlerden oluşan büyüyen bir ağ olan PlayStation Playmaker'larla tanışın.",
  };
  public post4 = {
    type: 'Gönderi',
    image:
      'https://mixed-news.com/en/wp-content/uploads/2022/11/Playstation-VR-2-Blue-Backdrop.jpeg',
    content:
      "PlayStation VR2'nin bugün dünya çapında piyasaya sürülmesiyle yeni nesil sanal gerçeklik oyunları başlıyor. Özellikleri ve oyun dizisi hakkında daha fazla bilgi edinin:",
  };
}
