<div class="content" style="min-height: 100vh">
  <div class="mainTitle d-flex justify-content-between">
    <div class="title">{{ title }}</div>
    <div class="filters d-flex align-items-center justify-content-end" style="gap: 10px;">
      <div class="buttons">
       
        <button
          mat-flat-button
          [class.active]="activeButton === 1"
          (click)="duzenlemeClick()"
        >
          Düzenleme
        </button>
        <button
          mat-flat-button
          [class.active]="activeButton === 2"
          *ngIf="showPreview"
          (click)="onizleClick()"
        >
          Ön İzleme
        </button>
      </div>
     
      <div class="save">
        <button mat-flat-button [loading]="loading" color="primary" (click)="submit()">Kaydet</button>
      </div>
    </div>
  </div>

  <order-sections
    [contents]="contents"
    [suggested]="suggestedContents"
    *ngIf="bolum"
  ></order-sections>

  <ng-container>
    <ng-container *ngTemplateOutlet="editingTemplate"></ng-container>
  </ng-container>
  <ng-container>
    <ng-container *ngTemplateOutlet="previewTemplate"></ng-container>
  </ng-container>
  
</div>
