import { Component, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { File } from 'src/app/models/file';

@Component({
  selector: 'post-video',
  templateUrl: './post-video.component.html',
  styleUrls: ['./post-video.component.scss'],
})
export class PostVideoComponent {
  @Input() public video: File;
  constructor(public sanitizer: DomSanitizer) {}

  public getVideoUrl() {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.video.streamData.preview);
  }
}
