<div class="container content">
  <div *ngIf="month$ | async as month">
    <div class="content">
      <div class="grid">
        <div class="dayName" *ngFor="let day of daysOfWeek">
          {{ day }}
        </div>
        <div class="day" *ngFor="let day of month">
          <div class="header">
            {{ getHeader(day.date) }}
          </div>
          <div class="event" *ngFor="let item of day.items">
            <post-calendar-list-item *ngIf="isPost(item)" [post]="item"></post-calendar-list-item>
            <calendar-event-list-item *ngIf="isEvent(item)" [item]="item"></calendar-event-list-item>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
