import { Component, Input } from '@angular/core';
import { Event } from 'src/app/models/event';

@Component({
  selector: 'event-profile-about',
  templateUrl: './event-profile-about.component.html',
  styleUrls: ['./event-profile-about.component.scss','../panel.scss']
})
export class EventProfileAboutComponent {

  @Input() public event: Event;

}
