import { FormBuilder, FormGroup } from '@angular/forms';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AuthService } from '@common/auth/auth.service';
import { Post } from 'src/app/models/post';
import { PostsService } from '../posts.service';

@Component({
  selector: 'write-comment',
  templateUrl: './write-comment.component.html',
  styleUrls: ['./write-comment.component.scss']
})
export class WriteCommentComponent {

  constructor(
    public auth:AuthService,
    public fb:FormBuilder,
    public posts:PostsService
  ) { }

  public formGroup = this.fb.group({
    text:[],
  });

  @Input() post:Post;
  @Output() replyAdded = new EventEmitter();


  public submit() {
    const data:any = {...this.formGroup.value};
    data.selectedProfileId = this.auth.selectedMember.profileId;
    data.selectedProfileType = this.auth.selectedMember.profileType;
    this.posts.replyPost(this.post.id, data).subscribe(data => {
      this.formGroup.reset();
      this.replyAdded.emit(data.data);
    });

  }

}
