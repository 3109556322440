<div class="contents">
  <form [formGroup]="profileEditing.formGroup">
    <div class="row" style="width: 100%;">
      <div class="col-md-3">
        <mat-action-list>
          <ng-container *ngFor="let item of menu; let i = index;">
            <button *ngIf="!item.hide()"  [activated]="selectedIndex == i" mat-list-item (click)="selectedIndex = i">
              <div class="d-inline-flex align-items-center" style="gap: 10px">
                <mat-icon>{{item.icon}}</mat-icon>
                <span>{{item.name}}</span>
              </div>
            </button>
          </ng-container>
        </mat-action-list>
      </div>
      <div class="col-md-9">
        <profile-editing-general
          *ngIf="selectedIndex == 0"
        ></profile-editing-general>

        <profile-editing-about
          *ngIf="selectedIndex == 1"
        ></profile-editing-about>

        <profile-editing-contact
          *ngIf="selectedIndex == 2"
        ></profile-editing-contact>

        <profile-editing-featured-content
          *ngIf="selectedIndex == 3"
        ></profile-editing-featured-content>

        <profile-editing-school-life
          *ngIf="
            selectedIndex == 4 &&
            (profileData.type == 'University' || profileData.type == 'Company')
          "
        ></profile-editing-school-life>

        <profile-editing-extra-sections
          *ngIf="selectedIndex == 5 && profileData.type == 'User'"
        ></profile-editing-extra-sections>
      </div>
    </div>
  </form>
</div>
