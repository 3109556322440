import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@common/auth/auth.service';
import { EventsService } from '@common/services/events.service';
import { FollowersService } from '@common/services/followers.service';
import { HomeService } from '@common/services/home.service';
import { ProjectsService } from '@common/services/projects.service';
import { switchMap, BehaviorSubject, map } from 'rxjs';
import { Project } from 'src/app/models/project';

@Component({
  selector: 'project-profile',
  templateUrl: './project-profile.component.html',
  styleUrls: ['./project-profile.component.scss'],
})
export class ProjectProfileComponent {
  activeStep: number = 0;
  projectId: string;
  constructor(
    private projects: ProjectsService,
    private activatedRoute: ActivatedRoute,
    public homeService: HomeService,
    public auth: AuthService,
    public followerService: FollowersService
  ) {}

  @Input() preview: Project;

  public project$ = this.activatedRoute.params
    .pipe(
      switchMap((params) => {
        if (this.preview) {
          return new BehaviorSubject({ data: this.preview });
        }
        this.projectId = params['id'];
        return this.projects.get(params['id']);
      })
    )
    .pipe(map((response) => response.data));

  public commonPoints$ = this.activatedRoute.params
    .pipe(
      switchMap((params) => {
        return this.projects.getCommonPoints(params['id']);
      })
    )
    .pipe(map((res) => res.data));

  public youMightKnow$ = this.homeService
    .youMightKnow()
    .pipe(map((res) => res.data));
  getActiveTap(activeStep: number) {
    this.activeStep = activeStep;
  }

  public toggle($event, user) {
    $event.stopPropagation();
    this.followerService.toogleExtended(user).subscribe();
  }
}
