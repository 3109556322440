import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { UploadService } from './../../../../../common/core/upload/upload.service';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@common/auth/auth.service';
import { Toast } from '@common/core/toast/toast/toast.service';
import { eventColors } from '@common/core/utils/event-colors';
import { image } from '@common/core/utils/image';
import { PaginatorService } from '@common/services/paginator.service';
import { ProfileService } from '@common/services/profile.service';
import { ProjectsService } from '@common/services/projects.service';
import { TextEditorComponent } from '@common/text-editor/text-editor.component';
import { AddEventApplicationDialogComponent } from '@common/ui/add-event-application-dialog/add-event-application-dialog.component';
import { map } from 'rxjs';
import { AddProjectTimelineComponent } from 'src/app/admin/projects/edit-project/add-project-timeline/add-project-timeline.component';
import { ProjectEditingService } from 'src/app/admin/projects/project-editing.service';
import { Project } from 'src/app/models/project';
import { Profile } from 'src/app/models/profile';
import { ProfileEditingService } from '../../profile-editing.service';

@Component({
  selector: 'edit-profile-fields',
  templateUrl: './edit-profile-fields.component.html',
  styleUrls: ['./edit-profile-fields.component.scss'],
})
export class EditProfileFieldsComponent implements OnInit {
  constructor(
    public uploadService: UploadService,
    public fb: FormBuilder,
    public route: ActivatedRoute,
    public profiles: ProfileService,
    public dialog: MatDialog,
    public paginator: PaginatorService,
    public projects: ProjectsService,
    public toast: Toast,
    public auth: AuthService,
    public profileEditing: ProfileEditingService
  ) {}

  public menu = [
    {
      name: 'Genel Bilgiler',
      icon: 'account_circle',
      hide: () => false,
    },
    {
      name: 'Hakkında',
      icon: 'info',
      hide: () => false,
    },
    {
      name: 'İletişim Bilgileri',
      icon: 'contact_mail',
      hide: () => false,
    },
    {
      name: 'Öne Çıkarılan İçerikler',
      icon: 'star',
      hide: () => false,
    },
    {
      name: 'Kurum Yaşamı',
      icon: 'business',
      hide: () => this.profileData.type != 'Company' && this.profileData.type != 'School',
    },
    {
      name: 'Extra Bilgiler',
      icon: 'description',
      hide: () => this.profileData.type != 'User',
    },
  ];
  public selectedIndex = 0;

  @Input() public profileData: Profile;
  @ViewChild('aboutEditor') aboutEditor: TextEditorComponent;
  @Output() afterInit = new EventEmitter();

  public aboutContent = '';

  public get formGroup() {
    return this.profileEditing.formGroup;
  }

  public colors = eventColors;

  public get tags() {
    return this.formGroup.controls['tags'] as FormArray;
  }

  public get applications() {
    return this.formGroup.controls['applications'] as FormArray;
  }

  public get tickets() {
    return this.formGroup.controls['tickets'] as FormArray;
  }

  public get addContributorControl() {
    return this.formGroup.get('addContributor') as FormControl;
  }

  public get addParticipantControl() {
    return this.formGroup.get('addParticipant') as FormControl;
  }

  public get contactInfo() {
    return this.formGroup.controls['contactList'] as FormArray;
  }

  public get participants() {
    return this.formGroup.controls['participants'] as FormArray;
  }

  public get contributors() {
    return this.formGroup.controls['contributors'] as FormArray;
  }

  public get timeline() {
    return this.formGroup.controls['timeline'] as FormArray<any>;
  }

  public get featuredContent() {
    return this.formGroup.controls['featuredContent'] as FormArray<any>;
  }

  public dayArray = [];

  public addApplication() {
    const ref = this.dialog.open(AddEventApplicationDialogComponent, {
      data: {
        eventId: this.profileData.id,
      },
    });
    ref.afterClosed().subscribe((section) => {
      if (section) {
        this.applications.push(this.fb.control(section));
      }
    });
  }

  public editApplication(oldApplication: any) {
    const ref = this.dialog.open(AddEventApplicationDialogComponent, {
      data: {
        eventId: this.profileData.id,
        oldValue: oldApplication,
      },
    });
    ref.afterClosed().subscribe((application) => {
      if (application) {
        const index = this.applications.controls.indexOf(oldApplication);
        this.applications.controls[index].patchValue(application);
      }
    });
  }

  public applicationPrice(application: any) {
    return application.price == 0
      ? 'Ücretsiz'
      : application.price + application.currency;
  }

  public addTimelineSection() {
    const ref = this.dialog.open(AddProjectTimelineComponent, {
      data: {},
    });
    ref.afterClosed().subscribe((section) => {
      if (section) {
        console.log(section);
        this.timeline.push(this.fb.control(section));
      }
    });
  }

  public editTimelineSection(oldSection: AbstractControl) {
    const ref = this.dialog.open(AddProjectTimelineComponent, {
      data: {
        section: oldSection.value,
      },
    });
    ref.afterClosed().subscribe((section) => {
      if (section) {
        const index = this.timeline.controls.indexOf(oldSection);
        this.timeline.controls[index].patchValue(section);
      }
    });
  }

  public removeTimelineSection(control: AbstractControl) {
    this.timeline.removeAt(this.timeline.controls.indexOf(control));
  }

  public moveSection(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this.timeline.controls,
      event.previousIndex,
      event.currentIndex
    );
  }

  public addContributor() {
    const contributor = this.formGroup.get('addContributor') as any;
    this.contributors.push(
      this.fb.control({
        profileId: contributor.value.id,
        profileType: contributor.value.profileType,
        profile: contributor.value,
      })
    );
    this.formGroup.get('addContributor').reset();
  }

  public removeContributor(index) {
    this.contributors.removeAt(index);
  }

  public moveParticipant(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this.participants.controls,
      event.previousIndex,
      event.currentIndex
    );
  }

  public moveContributor(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this.contributors.controls,
      event.previousIndex,
      event.currentIndex
    );
  }

  public uploadAvatar() {
    this.uploadService
      .openUploadDialog$('image', false, {
        aspectRatio: 1,
      })
      .subscribe((files) => {
        this.formGroup.get('general.avatarFile').setValue(files[0]);
        this.formGroup
          .get('general.avatarUrl')
          .setValue(files[0].imagesData.variants[0]);
      });
  }

  public uploadBackdrop() {
    this.uploadService
      .openUploadDialog$('image', false, {
        aspectRatio: 16 / 9,
      })
      .subscribe((files) => {
        this.formGroup.get('general.backdropFile').setValue(files[0]);
        this.formGroup
          .get('general.backdropUrl')
          .setValue(files[0].imagesData.variants[0]);
      });
  }

  public removeAvatar() {
    this.formGroup.get('general.avatarFile').setValue(null);
    this.formGroup.get('general.avatarUrl').setValue(null);
  }

  public removeBackdrop() {
    this.formGroup.get('general.backdropFile').setValue(null);
    this.formGroup.get('general.backdropUrl').setValue(null);
  }

  public addContactInfo() {
    const contactInfo = this.formGroup.get('addContactInfo');
    this.contactInfo.push(this.fb.control(contactInfo.value));
    this.formGroup.get('addContactInfo').reset();
  }

  public removeContactInfo(index) {
    this.formGroup.get('contactList').value.splice(index, 1);
  }

  public addFeaturedContent(event: any) {
    this.featuredContent.push(this.fb.control(event));
  }

  public removeFeaturedContent(index) {
    this.featuredContent.removeAt(index);
  }

  public convertDate(date: Date) {
    // yyyy-mm-dd
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${year}-${month < 10 ? '0' + month : month}-${
      day < 10 ? '0' + day : day
    }`;
  }

  public get extraSections() {
    return this.formGroup.get('extraSections') as FormArray;
  }

  public submit() {
    this.profileEditing.submit(this.profileData);
  }
  public key = '';
  ngOnInit(): void {
    if (!this.profileData) return;

    this.key = 'admin.project.' + this.profileData.id + '.participants';

    this.aboutContent = this.profileData.description;

    if (this.formGroup.get('id').value == this.profileData.id) return;

    this.formGroup.get('id').setValue(this.profileData.id);
    this.formGroup.get('general.name').setValue(this.profileData.name);
    if (this.profileData.type == 'User') {
      this.formGroup.get('general.name').disable();
    }
    this.formGroup.get('general.headnote').setValue(this.profileData.headnote);

    this.formGroup.get('about').setValue(this.profileData.about);
    this.formGroup.get('life').setValue(this.profileData.life);

    this.formGroup
      .get('general.avatarFile')
      .patchValue(this.profileData.avatarFile);
    this.formGroup.get('general.avatarUrl').setValue(this.profileData.avatar);

    this.formGroup
      .get('general.backdropFile')
      .setValue(this.profileData.backdropFile);
    this.formGroup
      .get('general.backdropUrl')
      .setValue(this.profileData.backdrop);

    for (let tag of this.profileData.tags) {
      this.tags.push(this.fb.control(tag));
    }

    for (let featuredContent of (this.profileData.featuredContent || [])) {
      
      this.featuredContent.push(this.fb.control(featuredContent));
    }

    for (let contact of this.profileData.contactList || []) {
      this.contactInfo.push(this.fb.control(contact));
    }

    for (let extra of (this.profileData.extraSections || [])) {
      this.extraSections.push(this.fb.control(extra));
    }
  }

  public onAboutChange() {
    this.formGroup.get('about').setValue(this.aboutEditor.getContents());
  }
}
