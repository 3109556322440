import { Component, OnInit } from '@angular/core';
import { FollowersService } from '@common/services/followers.service';
import { BehaviorSubject, filter, map } from 'rxjs';
import { MessagingService } from '../../messaging.service';
import { FormArray, FormBuilder } from '@angular/forms';
import { Profile } from 'src/app/models/profile';
import { Toast } from '@common/core/toast/toast/toast.service';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';

@Component({
  selector: 'new-chat',
  templateUrl: './new-chat.component.html',
  styleUrls: ['./new-chat.component.scss'],
})
export class NewChatComponent implements OnInit {
  constructor(
    public followers: FollowersService,
    public messaging: MessagingService,
    public fb: FormBuilder,
    public toast: Toast,
    public ref: MatBottomSheetRef<NewChatComponent>
  ) {}

  public form = this.fb.group({
    name: [''],
    user: [''],
    users: this.fb.array([]),
    type: ['dm'],
  });

  currentPage = 1;
  public avaibleContacts$ = new BehaviorSubject<Profile[]>([]);

  public checkForEmptyList() :boolean {
    const user = this.form.get('user').value as any;
    if (!user) return true;
    let obj = user;
    if (typeof user === 'string' && this.avaibleContacts$.value.length > 0)
      obj = this.avaibleContacts$.value[0];
    if (typeof obj !== 'string') {
      this.users.push(this.fb.control(obj));
    }
    return this.users.length === 0;
  }

  public openRoom() {
    console.log(this.form.value);
    if (this.users.length === 0 && this.checkForEmptyList()) {
      this.toast.open('En az 1 kişi seçmelisiniz.');
      return;
    }
    if (this.form.valid) {
      this.ref.dismiss(this.form.value);
    }
  }

  public get users() {
    return this.form.get('users') as FormArray;
  }

  public getName(data: any) {
    return data && (data.display_name || data.name);
  }

  public addUser() {
    const user = this.form.get('user').value as any;
    if (!user) return;

    let obj = user;
    if (typeof user === 'string' && this.avaibleContacts$.value.length > 0)
      obj = this.avaibleContacts$.value[0];

    if (!obj || typeof obj === 'string') {
      return;
    }

    if (this.form.value.type === 'dm' && this.users.length > 0) {
      this.toast.open('Özel mesajlar için yalnızca 1 kişi seçebilirsiniz.');
      return;
    }
    if (this.users.value.find((u) => u.id === obj.id)) {
      this.toast.open('Bu kullanıcı zaten eklendi.');
      return;
    }
    this.users.push(this.fb.control(obj));
    this.form.get('user').setValue('');
    this.avaibleContacts$.next([]);
  }

  public removeUser(index: number) {
    this.users.removeAt(index);
  }

  ngOnInit(): void {
    this.form
      .get('user')
      .valueChanges.pipe(
        filter((item: any) => {
          return typeof item === 'string' && item.length > 0;
        })
      )
      .subscribe((val) => {
        this.followers.getAvaibleChatUsers(val).subscribe((users) => {
          this.avaibleContacts$.next(users);
        });
      });
  }
}
