import { Component, Input } from '@angular/core';
import { Project } from 'src/app/models/project';

@Component({
  selector: 'project-profile-participants',
  templateUrl: './project-profile-participants.component.html',
  styleUrls: ['./project-profile-participants.component.scss', '../panel.scss'],
})
export class ProjectProfileParticipantsComponent {
  @Input() project: Project;
  @Input() title: string = 'Katılımcılar';
  public get participants() {
    return this.project.participants?.slice(0, 4);
  }
}
