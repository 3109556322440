import { Component, Input } from '@angular/core';
import { Post } from 'src/app/models/post';
import { PostsService } from '../posts.service';
import { Toast } from '@common/core/toast/toast/toast.service';

@Component({
  selector: 'post-reply',
  templateUrl: './post-reply.component.html',
  styleUrls: ['./post-reply.component.scss']
})
export class PostReplyComponent {
  @Input('post') post: Post;
  @Input('showCommentButton') showCommentButton = true;
  @Input() depth = 0;

  constructor(public postService: PostsService, private toast: Toast) {}

  public isSaved: boolean;
  public showReplies = false;

  public share() {
    const link = 'https://unilocked.com/posts/' + this.post.id;
    navigator.clipboard.writeText(link).then(() => {
      this.toast.open('Bağlantı kopyalandı.');
    });
  }

  ngOnInit(): void {
    this.isSaved = this.postService.isSaved(this.post);
  }

  public savePost() {
    this.postService.savePost(this.post).subscribe((post) => {
      this.isSaved = true;
    });
  }

  public unsavePost() {
    this.postService.unsavePost(this.post).subscribe((post) => {
      this.isSaved = false;
    });
  }

  public addReaction(reaction) {
    this.postService.addReaction(this.post, reaction).subscribe((post) => {
      this.post.reactionsCounts = post.reactionsCounts;
    });
  }
}
