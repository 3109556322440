<h1 mat-dialog-title>Deneyim Ekle</h1>
<div mat-dialog-content style="width: 100%">
  <form [formGroup]="formGroup">
    <ng-template matStepLabel>Genel Bilgiler</ng-template>
    <div>
      <h2>Eklemek istediğiniz türü seçin</h2>
      <mat-form-field appearance="outline" style="width: 100%">
        <mat-label>Tür</mat-label>
        <mat-select formControlName="type" required>
          <mat-option value="experience">Deneyim</mat-option>
          <mat-option value="education">Eğitim</mat-option>
          <mat-option value="certificate">Sertifika</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div>
      <h2>{{ nameByType }} İsmini Girin</h2>
      <mat-form-field appearance="outline" style="width: 100%">
        <mat-label>İsim</mat-label>
        <input
          matInput
          placeholder=""
          formControlName="organization"
          required
        />
      </mat-form-field>
    </div>
    <div>
      <h2>{{ descriptionByType }}</h2>
      <mat-form-field appearance="outline" style="width: 100%">
        <mat-label>Kısa açıklama</mat-label>
        <input matInput placeholder="" formControlName="name" required />
      </mat-form-field>
    </div>
    <div>
      <h2>Kazandığınız yetenekleri girin.</h2>
      <mat-form-field
        appearance="outline"
        class="example-chip-list"
        style="width: 100%"
      >
        <mat-label>Yetenekler</mat-label>
        <mat-chip-grid #chipGrid aria-label="Yetenek girin">
          <mat-chip-row
            *ngFor="let tag of talents.controls"
            (removed)="remove(tag.value)"
            [editable]="true"
            (edited)="edit(tag.value, $event)"
            [aria-description]="'press enter to edit ' + tag.value"
          >
            {{ tag.value }}
            <button matChipRemove [attr.aria-label]="'remove ' + tag.value">
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip-row>
          <input
            placeholder="Yeni yetenek..."
            [matChipInputFor]="chipGrid"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            [matChipInputAddOnBlur]="true"
            (matChipInputTokenEnd)="add($event)"
          />
        </mat-chip-grid>
      </mat-form-field>
    </div>
    <div>
      <h2>Başlangıç tarihi girin</h2>
      <mat-form-field appearance="outline" style="width: 100%;">
        <mat-label>Tarih girin</mat-label>
        <input matInput [matDatepicker]="picker" formControlName="startDate" />
        <mat-hint>GG/AA/YYYY</mat-hint>
        <mat-datepicker-toggle
          matIconSuffix
          [for]="picker"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>
    <div>
      <h2>Bitiş tarihi girin</h2>
      <mat-form-field appearance="outline" style="width: 100%;">
        <mat-label>Tarih girin</mat-label>
        <input matInput [matDatepicker]="picker2" formControlName="endDate" />
        <mat-hint>GG/AA/YYYY</mat-hint>
        <mat-datepicker-toggle
          matIconSuffix
          [for]="picker2"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker2></mat-datepicker>
      </mat-form-field>
    </div>
    <div>
      <h2>Kalan kısımları açıklayın.</h2>
      <text-editor-form formControlName="description"></text-editor-form>
    </div>
  </form>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close="">İptal</button>
  <button mat-button cdkFocusInitial (click)="submit()" *ngIf="formGroup.valid">
    Kaydet
  </button>
</div>
