<mat-card class="event-card hover-effect-card" [ngClass]="getStatusColor()">
  <div class="top">
    <div class="title d-flex justify-content-between align-items-center">
      <span class="time d-flex align-items-center">
        <mat-icon> {{ getStatusIcon() }} </mat-icon>
        {{post.visibleFrom | dateHour}}
      </span>
      <mat-icon> more_horiz </mat-icon>
    </div>
    <div class="subtitle">{{getStatusText()}} • {{getVisibilityText()}}</div>
  </div>
  <div class="content">
    <div class="text">
      <span [innerHTML]="post.text.slice(0, 50)"></span>
    </div>
    <div class="actions d-flex align-items-center justify-content-between">
      <mat-icon> query_stats </mat-icon>
      <mat-icon> edit </mat-icon>
      <mat-icon> message </mat-icon>
      <mat-icon> share </mat-icon>
    </div>
  </div>
</mat-card>
