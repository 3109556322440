<main-content *ngIf="navbar">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</main-content>
<ng-container *ngIf="!navbar">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</ng-container>
<ng-template #content>
  <div class="container" *ngIf="jobPosting">
    <mat-card class="job-card">
      <div class="head">
        <h1>{{ jobPosting.title }}</h1>
        <div>
          <button
            mat-flat-button
            class="button-primary"
            [routerLink]="['/job-posting', jobPosting.id, 'application']"
          >
            Başvur
          </button>
        </div>
      </div>
      <div class="info">
        <div class="locations">
          <p class="location" *ngFor="let location of jobPosting.locations">
            <mat-icon>location_on</mat-icon>
            {{ location }}
          </p>
        </div>
        <div class="workTime">
          <mat-icon>schedule</mat-icon>
          {{ jobPosting.workingTime }} gün •
          {{
            jobPosting.jobType == "Full time" ? "Tam Zamanlı" : "Yarı Zamanlı"
          }}
          • {{ jobPosting.workType == "Remote" ? "Uzaktan" : "Ofiste" }}
        </div>
        <div class="viewDate">
          {{ jobPosting.viewStartDate | dateAgo }} yayınlandı
        </div>
      </div>
      <div class="talents st">
        <h2>Yetenekler</h2>
        <mat-chip-set>
          <mat-chip *ngFor="let talent of jobPosting.talents">{{
            talent.name
          }}</mat-chip>
        </mat-chip-set>
      </div>
      <div class="description st">
        <h2>İlan Açıklaması</h2>
        <p [innerHTML]="jobPosting.description"></p>
      </div>
      <div class="company st">
        <h2>Şirket Hakkında</h2>
        <div class="company-card">
          <div class="image">
            <img
              [src]="jobPosting.creator.avatar || '/assets/img/default.png'"
              alt="company"
            />
          </div>
          <div class="mid">
            <p class="name">{{ jobPosting.creator.display_name }}</p>
            <p class="headnote">
              {{ jobPosting.creator.headnote }}
            </p>
          </div>
          <div class="end">
            <button mat-flat-button class="button-primary">Takip Et</button>
          </div>
        </div>
      </div>
    </mat-card>
  </div>
</ng-template>
