import { FormBuilder } from '@angular/forms';
import { FeaturedContentService } from './../../../../common/services/featured-content.service';
import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'add-featured-content',
  templateUrl: './add-featured-content.component.html',
  styleUrls: ['./add-featured-content.component.scss']
})
export class AddFeaturedContentComponent {

  constructor(
    private featuredContentService: FeaturedContentService,
    private fb:FormBuilder
  ) { }

  @Output() public onAdd = new EventEmitter();

  public formGroup = this.fb.group({
    url: ['']
  });

  public submit() {
    this.featuredContentService.resolveFeaturedContent(this.formGroup.get('url').value).subscribe((response) => {
      this.onAdd.emit(response.data);
    });
  }

}
