<div class="write-comment">
    <form [formGroup]="formGroup" (submit)="submit()">
        <div class="top">
            <div class="logo">
              <img [src]="auth.selectedProfile.avatar" alt="logo" />
            </div>
            <div class="editor">
              <textarea
                matInput
                formControlName="text"
                class="comment"
                [placeholder]="'Bu gönderi hakkında neler söyleyeceksiniz?'"
              >
            </textarea>
            <button mat-icon-button>
              <mat-icon>send</mat-icon>
            </button>
            </div>
          </div>
    </form>
  </div>