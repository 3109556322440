import { Component, Input } from '@angular/core';
import { Project } from 'src/app/models/project';

@Component({
  selector: 'project-profile-timeline',
  templateUrl: './project-profile-timeline.component.html',
  styleUrls: ['./project-profile-timeline.component.scss','../panel.scss']
})
export class ProjectProfileTimelineComponent {
  @Input() public project: Project;
}
