import { Component, Input } from '@angular/core';
import { Project } from 'src/app/models/project';

@Component({
  selector: 'project-profile-contact',
  templateUrl: './project-profile-contact.component.html',
  styleUrls: ['./project-profile-contact.component.scss', '../panel.scss'],
})
export class ProjectProfileContactComponent {
  @Input() public project: Project;
}
