import { Directive, TemplateRef } from '@angular/core';

@Directive({
  selector: '[editPanelPreview]'
})
export class EditPanelPreviewDirective {

  constructor(public template: TemplateRef<any>) {}

}
