import { Component, Input } from '@angular/core';
import { Event } from 'src/app/models/event';

@Component({
  selector: 'event-profile-featured-content',
  templateUrl: './event-profile-featured-content.component.html',
  styleUrls: ['./event-profile-featured-content.component.scss', '../panel.scss']
})
export class EventProfileFeaturedContentComponent {

  @Input() public event: Event;
}
