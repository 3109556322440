import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '@common/auth/auth.service';
import { StandartResponse } from '@common/core/http/standart-response';
import { EventsService } from '@common/services/events.service';
import { AddEventDialogComponent } from '@common/ui/add-event-dialog/add-event-dialog.component';
import { BehaviorSubject, switchMap, map } from 'rxjs';
import { Observable } from 'tinymce';

@Component({
  selector: 'calendar-month',
  templateUrl: './calendar-month.component.html',
  styleUrls: ['./calendar-month.component.scss'],
})
export class CalendarMonthComponent {
  constructor(public dialog: MatDialog, public auth: AuthService) {}

  public isPost = (item: any) => item.type === 'post' || item?.text;
  public isEvent = (item: any) => item.type === 'event' || item?.startDate;

  
  public dataObserver$: any = new BehaviorSubject((date: Date) => {
    return new BehaviorSubject<
      StandartResponse<
        {
          date: Date;
          items: any[];
        }[]
      >
    >(null);
  });
  public currentDay = new BehaviorSubject(new Date());
  public loading$ = new BehaviorSubject(true);

  public month$ = this.currentDay
    .pipe(
      switchMap((date) => {
        return this.dataObserver$;
      })
    )
    .pipe(switchMap((observer: any) => observer(this.firstMonday)))
    .pipe(
      map((data: any) => {
        this.loading$.next(false);
        return data?.data || [];
      })
    );

  public get firstDayOfMonth() {
    return new Date(
      this.currentDay.value.getFullYear(),
      this.currentDay.value.getMonth(),
      1
    );
  }

  public get firstMonday() {
    const date = new Date(this.firstDayOfMonth);
    while (date.getDay() !== 1) {
      date.setDate(date.getDate() - 1);
    }
    console.log(date, date.toLocaleDateString('tr-TR', { weekday: 'long' }));
    return date;
  }

  public get lastDayOfMonth() {
    return new Date(
      this.currentDay.value.getFullYear(),
      this.currentDay.value.getMonth() + 1,
      0
    );
  }

  public get daysOfWeek() {
    return [
      'Pazartesi',
      'Salı',
      'Çarşamba',
      'Perşembe',
      'Cuma',
      'Cumartesi',
      'Pazar',
    ];
  }

  public getHeader(date: Date) {
    const currentMonthNumber = this.firstDayOfMonth.getMonth() + 1;
    date = new Date(date);
    const dateMonthNumber = date.getMonth() + 1;

    const dateDay = date.getDate();
    const dayString = dateDay < 10 ? `0${dateDay}` : `${dateDay}`;
    const monthString =
      dateMonthNumber < 10 ? `0${dateMonthNumber}` : `${dateMonthNumber}`;
    if (dateMonthNumber == currentMonthNumber) {
      return `${dayString}`;
    } else {
      return `${dayString}.${monthString}`;
    }
  }

  public next() {
    this.currentDay.next(
      new Date(
        this.currentDay.value.getFullYear(),
        this.currentDay.value.getMonth() + 1,
        1
      )
    );
    this.loading$.next(true);
  }

  public prev() {
    this.currentDay.next(
      new Date(
        this.currentDay.value.getFullYear(),
        this.currentDay.value.getMonth() - 1,
        1
      )
    );
    this.loading$.next(true);
  }

  public openCreateEventDialog() {
    const ref = this.dialog.open(AddEventDialogComponent, {
      panelClass: 'add-event-dialog',
    });
    ref.afterClosed().subscribe((result) => {
      if (result) {
        this.currentDay.next(this.currentDay.value);
      }
    });
  }
}
