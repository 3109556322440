<div class="contents">
  <div class="content">
    <div class="header d-flex justify-content-between align-items-center">
      <p>Bölüm Düzeni</p>
      <button class="d-flex justify-content-around align-items-center">
        <mat-icon> add </mat-icon>
        Bölüm Ekle
      </button>
    </div>
    <div class="main">
      <ul cdkDropList (cdkDropListDropped)="move($event)">
        <li
          class="dragItem"
          *ngFor="let list of contents; let i = index"
          cdkDrag
        >
          <div class="info">
            <div class="order-icon" cdkDragHandle>
              <mat-icon> menu </mat-icon>
            </div>
            <div class="title">
              <b>{{ list.title }}</b>
              <p *ngIf="list.subtitle === 'yes'" style="color: #40c057">
                <mat-icon> done </mat-icon>
                Bu bölümün sayfanız için doğru noktada olduğunu öngörüyoruz.
              </p>
              <p *ngIf="list.subtitle === 'no'" style="color: #fa5252">
                <mat-icon> close </mat-icon>
                Bu bölümün sayfanız için yanlış noktada olduğunu öngörüyoruz. •
                İletişim bölümünün sayfa sonunda bulunmasının daha olumlu etkiye
                sahip olduğu gözlendi.
              </p>
              <p *ngIf="list.subtitle != 'yes' && list.subtitle != 'no'">
                {{ list.subtitle }}
              </p>
            </div>
          </div>
          <div class="buttons">
            <mat-icon> edit </mat-icon>
            <mat-icon> visibility </mat-icon>
            <mat-icon> delete </mat-icon>
            <mat-icon> more_horiz </mat-icon>
          </div>
        </li>
      </ul>
    </div>
  </div>

  <div class="content" *ngIf="suggested?.length > 0">
    <div class="header d-flex justify-content-between align-items-center">
      <p>Önerilen Bölümler</p>
    </div>
    <div class="main">
      <ul>
        <li class="dragItem" *ngFor="let list of suggested; let i = index">
          <div class="info">
            <div class="title">
              <b>{{ list.title }}</b>
              <p *ngIf="list.subtitle === 'yes'" style="color: #40c057">
                <mat-icon> done </mat-icon>
                Bu bölümün sayfanız için doğru noktada olduğunu öngörüyoruz.
              </p>
              <p *ngIf="list.subtitle === 'no'" style="color: #fa5252">
                <mat-icon> close </mat-icon>
                Bu bölümün sayfanız için yanlış noktada olduğunu öngörüyoruz. •
                İletişim bölümünün sayfa sonunda bulunmasının daha olumlu etkiye
                sahip olduğu gözlendi.
              </p>
              <p *ngIf="list.subtitle != 'yes' && list.subtitle != 'no'">
                {{ list.subtitle }}
              </p>
            </div>
          </div>

          <div class="buttons">
            <button mat-flat-button>Ekle</button>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>
