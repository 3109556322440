import { Injectable } from '@angular/core';
import { AppHttpClient } from '@common/core/http/http.service';
import { StandartResponse } from '@common/core/http/standart-response';
import { CreatePost } from '../models/CreatePost';
import { map } from 'rxjs/internal/operators/map';

@Injectable({
  providedIn: 'root'
})
export class PostService {

  constructor( public httpClient: AppHttpClient) { }
  public savePost(post: CreatePost) {
    return this.httpClient
      .post<StandartResponse<CreatePost>>(`posts`,post)
      .pipe(map((response) => response.data));
  }
}
